
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import { rulesRequiredPositiveNumber } from '@/application/Validators'
  import { BeteiligungFieldsFragment } from '@/generated/graphql'

  @Component
  export default class BeteiligungComponent extends Vue {

    @Prop({ required: true })
    readonly beteiligung!: BeteiligungFieldsFragment

    @Prop({ required: false, default: false })
    readonly disabled!: boolean

    readonly anteileRules = rulesRequiredPositiveNumber
    readonly betragRules = rulesRequiredPositiveNumber

    onFixbetragChanged(value: boolean): void {
      this.$emit('update:fixbetrag', value)
    }

    onAnteileChanged(value: string): void {
      this.$emit('update:anteile', value)
    }

    onBetragChanged(value: string): void {
      this.$emit('update:betrag', value)
    }
  }
