
import { Component, Prop, Vue } from 'vue-property-decorator'
import { TransaktionStatus } from '@/generated/graphql'

@Component
  export default class TransaktionStatusIcon extends Vue {

    readonly inbearbeitung = TransaktionStatus.InBearbeitung
    readonly zuverbuchen = TransaktionStatus.ZuVerbuchen
    readonly ok = TransaktionStatus.Ok
    readonly storniert = TransaktionStatus.Storniert

    @Prop({ required: true })
    status!: TransaktionStatus

  }
