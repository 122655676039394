
import { fetchAsObjectUrl } from '@/application/RestApi'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component(
    {
      destroyed() {
        URL.revokeObjectURL(this.$data.dataSrc)
      }
    }
)
export default class FilePreview extends Vue {

  @Prop({ required: true })
  readonly src!: string

  @Prop({ required: false, default: null })
  readonly contentType!: string | null

  dataSrc = ''

  // noinspection JSUnusedGlobalSymbols
  async mounted() {
    if (this.src.startsWith('/api')) {
      this.dataSrc = await fetchAsObjectUrl(this.src)
    } else {
      this.dataSrc = this.src
    }
  }

  get isImage(): boolean {
    return this.contentType?.startsWith('image') || false
  }

  get embeddedSrc(): string | ArrayBuffer {
    return this.dataSrc + '#view=FitH'
  }
}
