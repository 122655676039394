
import { Component, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import { rulesRequiredNotNegativeNumber } from '@/application/Validators'
import {
  Member,
  MembersLohndatenQueryVariables,
  UpdateLohndatenDocument,
  UpdateLohndatenInput,
  UpdateLohndatenMutation,
  UpdateLohndatenMutationVariables,
  useMembersLohndatenQuery
} from '@/generated/graphql'

@Component({
  apollo: {
    members: useMembersLohndatenQuery<LoehnePage>({
      variables(): MembersLohndatenQueryVariables {
        return {
          year: this.selectedYear.toString()
        }
      }
    })
  }
})
export default class LoehnePage extends Vue {

  members: Member[] | null = null

  readonly rules: InputValidationRules = rulesRequiredNotNegativeNumber

  editPermitted = this.$store.state.benutzer.isBuchhalter

  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  saving = false

  async save(): Promise<void> {
    this.saving = true

    try {
      if (this.members) {
        const input: UpdateLohndatenInput[] = this.members.map(value => value.lohndaten).map(value => ({
          id: value.id,
          bruttoloehne: value.bruttoloehne,
          sozialversicherungenAG: value.sozialversicherungenAG,
          ktg: value.ktg,
          bonus: value.bonus,
          bonusNetto: value.bonusNetto,
          kinderzulagen: value.kinderzulagen,
          version: value.version
        }))

        await this.$apollo.mutate<UpdateLohndatenMutation, UpdateLohndatenMutationVariables>({
          mutation: UpdateLohndatenDocument,
          variables: {
            updateInfo: input
          }
        })

        this.$apollo.queries.members.refetch()
      }
    } finally {
      this.saving = false
    }
  }

}
