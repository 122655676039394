import Vue from 'vue'
import { Beteiligung, TransaktionStatus } from '@/generated/graphql'

const dateFormat = Intl.DateTimeFormat('de-CH', { day: '2-digit', month: '2-digit', year: 'numeric' })
Vue.filter('formatAsDate', (value: string) => dateFormat.format(Date.parse(value)))

Vue.filter('flattenBeteiligungen', (value: Beteiligung[]) => value.map(beteiligung => beteiligung.konto?.bezeichnung).join(', '))

const betragNumberFormat = Intl.NumberFormat('de-CH', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
})

Vue.filter('formatAsBetrag', (value: string, postfix?: string) => {
  const formattedNumber = betragNumberFormat.format(parseFloat(value))
  return postfix ? `${formattedNumber} ${postfix}` : formattedNumber
})

const monthOnlyFormatLong = (value: string) => Intl.DateTimeFormat('de-CH', { month: 'long' }).format(new Date(Date.parse(value)))
const monthOnlyFormatShort = (value: string) => Intl.DateTimeFormat('de-CH', { month: 'short' }).format(new Date(Date.parse(value)))

Vue.filter('formatAsMonth', (value: string) => {
  if (value.includes('-13')) {
    return 'Bonus (13-Monat)'
  } else {
    return monthOnlyFormatLong(value)
  }
})
Vue.filter('formatAsMonthShort', (value: string) => {
  return monthOnlyFormatShort(value)
})
const integerNumberFormat = Intl.NumberFormat('de-CH', {
  maximumFractionDigits: 0
})
Vue.filter('formatAsInteger', (value: string, postfix?: string) => {
  // https://abdulapopoola.com/2016/12/19/why-javascript-has-two-zeros-0-and-0/
  // avoid showing "-0" cause javascript distinguish between -0 and +0 - hello!!!
  const formattedNumber = parseFloat(value) === 0 ? '0' : integerNumberFormat.format(parseFloat(value))
  return postfix ? `${formattedNumber} ${postfix}` : formattedNumber
})

const arbeitsstundenNumberFormat = Intl.NumberFormat('de-CH', {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1
})
Vue.filter('formatAsArbeitsstunden', (value: string) => {
  return arbeitsstundenNumberFormat.format(parseFloat(value))
})

Vue.filter('statusText', (value: TransaktionStatus) => {
  switch (value) {
    case TransaktionStatus.InBearbeitung: return 'In Bearbeitung'
    case TransaktionStatus.ZuVerbuchen: return 'Zu verbuchen'
    case TransaktionStatus.Ok: return 'Erledigt'
    case TransaktionStatus.Storniert: return 'Storniert'
    default: return value
  }
})
