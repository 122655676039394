
import PhotoImg from '@/components/PhotoImg.vue'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import { required } from '@/application/Validators'
import BeteiligungComponent from '@/components/beteiligungen/BeteiligungComponent.vue'
import {
  BeteiligungFieldsFragment,
  BeteiligungInput,
  KontoFieldsFragment,
  Topf,
  TopfStatus, UpdateBeteiligteDocument,
  UpdateBeteiligteInput, UpdateBeteiligungAnteileDocument,
  UpdateBeteiligungAnteileInput, UpdateBeteiligungBetragDocument,
  UpdateBeteiligungBetragInput, UpdateBeteiligungFixbetragStatusDocument,
  UpdateBeteiligungFixbetragStatusInput, UpdateTotalBetragDocument,
  UpdateTotalBetragInput,
  UpdateTotalBetragQuery,
  UpdateTotalBetragQueryVariables
} from '@/generated/graphql'

@Component({
  components: { PhotoImg, BeteiligungComponent }
})
export default class BeteiligungenSelectionComponent extends Vue {

  @Prop({ required: true })
  readonly label!: string

  @Prop({
    required: false,
    default: false
  })
  readonly disabled!: boolean

  @Prop({ required: true })
  readonly availableKonten!: KontoFieldsFragment[]

  @Prop({ required: true })
  readonly beteiligungen!: BeteiligungFieldsFragment[]

  @Prop({ required: true })
  readonly totalBetrag!: string

  get selectedKontoIds(): string[] {
    return this.beteiligungen.map(value => value.kontoId)
  }

  readonly validationRules: InputValidationRules = [required]

  get kontenSelection(): Array<KontoFieldsFragment | { header: string } | { divider: boolean }> {
    const memberKonten = this.availableKonten.filter(konto => konto.besitzer.__typename === 'Member')

    const isTopfFromThisYear = (datum: string): boolean => new Date(Date.parse(datum)).getFullYear() === new Date().getFullYear()
    const topfKonten = this.availableKonten
        .filter(konto => konto.besitzer.__typename === 'Topf')
        .filter((konto) => {
          const topf = konto.besitzer as Topf
          return topf.status === TopfStatus.Offen && isTopfFromThisYear(topf.datum)
        })

    const kontenSelection: Array<KontoFieldsFragment | { header: string } | { divider: boolean }> = []
    if (memberKonten.length > 0) {
      kontenSelection.push({ header: 'Members' })
      kontenSelection.push(...memberKonten)
    }

    if (topfKonten.length > 0) {
      if (kontenSelection.length > 0) {
        kontenSelection.push({ divider: true })
      }

      kontenSelection.push({ header: 'Töpfe' })
      kontenSelection.push(...topfKonten)
    }

    return kontenSelection
  }

  @Watch('totalBetrag')
  async onTotalBetragUpdated(): Promise<void> {
    if (this.totalBetrag && this.totalBetrag !== '') {
      const input: UpdateTotalBetragInput = {
        baseline: {
          totalBetrag: this.totalBetrag,
          beteiligungen: this.mapBeteiligungenAsInput()
        },
        betrag: this.totalBetrag
      }

      const updatedBeteiligungen = await this.$apollo.query<UpdateTotalBetragQuery, UpdateTotalBetragQueryVariables>({
        query: UpdateTotalBetragDocument,
        variables: {
          input
        }
      })
      this.$emit('update:beteiligungen', updatedBeteiligungen.data.updateTotalBetrag)
    }
  }

  async onFixbetragUpdated(kontoId: string, fixbetragStatus: boolean | null): Promise<void> {

    const input: UpdateBeteiligungFixbetragStatusInput = {
      baseline: {
        totalBetrag: this.totalBetrag,
        beteiligungen: this.mapBeteiligungenAsInput()
      },
      kontoId,
      fixbetragStatus: fixbetragStatus === true
    }

    const updatedBeteiligungen = await this.$apollo.query({
      query: UpdateBeteiligungFixbetragStatusDocument,
      variables: {
        input
      }
    })

    this.$emit('update:beteiligungen', updatedBeteiligungen.data.updateBeteiligungFixbetragStatus)
  }

  async onAnteileUpdated(kontoId: string, anteile: string): Promise<void> {
    const input: UpdateBeteiligungAnteileInput = {
      baseline: {
        totalBetrag: this.totalBetrag,
        beteiligungen: this.mapBeteiligungenAsInput()
      },
      kontoId,
      anteile
    }

    const updatedBeteiligungen = await this.$apollo.query({
      query: UpdateBeteiligungAnteileDocument,
      variables: {
        input
      }
    })

    this.$emit('update:beteiligungen', updatedBeteiligungen.data.updateBeteiligungAnteile)
  }

  async onBetragUpdated(kontoId: string, betrag: string): Promise<void> {
    const input: UpdateBeteiligungBetragInput = {
      baseline: {
        totalBetrag: this.totalBetrag,
        beteiligungen: this.mapBeteiligungenAsInput()
      },
      kontoId,
      betrag
    }

    const updatedBeteiligungen = await this.$apollo.query({
      query: UpdateBeteiligungBetragDocument,
      variables: {
        input
      }
    })

    this.$emit('update:beteiligungen', updatedBeteiligungen.data.updateBeteiligungBetrag)
  }

  async onSelectedKontenChanged(kontoIds: string[]): Promise<void> {
    const input: UpdateBeteiligteInput = {
      baseline: {
        totalBetrag: this.totalBetrag,
        beteiligungen: this.mapBeteiligungenAsInput()
      },
      beteiligte: kontoIds
    }

    const updatedBeteiligungen = await this.$apollo.query({
      query: UpdateBeteiligteDocument,
      variables: {
        input
      }
    })

    this.$emit('update:beteiligungen', updatedBeteiligungen.data.updateBeteiligte)
  }

  remove(item: KontoFieldsFragment): void {
    const index = this.selectedKontoIds.indexOf(item.id)
    if (index >= 0) {
      this.beteiligungen.splice(index, 1)
    }
    this.onSelectedKontenChanged(this.selectedKontoIds)
  }

  private mapBeteiligungenAsInput(): BeteiligungInput[] {
    return this.beteiligungen.map(value => ({
      kontoId: value.kontoId,
      betrag: value.betrag,
      anteile: value.anteile
    }))
  }
}
