
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'

@Component
export default class DateField extends Vue {

  @Prop()
  readonly value!: string | undefined

  @Prop({ required: true })
  readonly label!: string

  @Prop()
  readonly rules!: InputValidationRules

  @Prop()
  readonly disabled!: boolean

  get datum(): string | undefined {
    return this.value
  }

  set datum(value: string | undefined) {
    this.$emit('input', value)
  }

  showDateMenu = false

}
