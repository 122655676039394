
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import { rulesRequired, rulesRequiredPositiveNumber } from '@/application/Validators'
import { BruttoNettoMwstBetrag, BruttoNettoMwstBetragInput, WithBetragBruttoDocument, WithBetragMwstDocument, WithBetragNettoDocument } from '@/generated/graphql'

@Component
export default class BetraegeComponent extends Vue {

  @Prop({ required: true })
  readonly betraege!: BruttoNettoMwstBetrag

  @Prop({ required: false, default: false })
  readonly disabled!: boolean

  readonly rulesRequired: InputValidationRules = rulesRequired
  readonly rulesRequiredPositiveNumber: InputValidationRules = rulesRequiredPositiveNumber

  async onBetragBruttoChanged(betrag: string): Promise<void> {
    const baseline = this.createBaselineInput()
    const response = await this.$apollo.mutate({
      mutation: WithBetragBruttoDocument,
      variables: {
        betrag,
        baseline
      }
    })

    this.$emit('update:betraege', response.data.withBetragBrutto)
  }

  async onBetragMwstChanged(betrag: string): Promise<void> {
    const baseline = this.createBaselineInput()
    const response = await this.$apollo.mutate({
      mutation: WithBetragMwstDocument,
      variables: {
        betrag,
        baseline
      }
    })

    this.$emit('update:betraege', response.data.withBetragMwst)
  }

  async onBetragNettoChanged(betrag: string): Promise<void> {
    const baseline = this.createBaselineInput()
    const response = await this.$apollo.mutate({
      mutation: WithBetragNettoDocument,
      variables: {
        betrag,
        baseline
      }
    })

    this.$emit('update:betraege', response.data.withBetragNetto)
  }

  private createBaselineInput(): BruttoNettoMwstBetragInput {
    return {
      betragBrutto: this.betraege.betragBrutto,
      betragMwst: this.betraege.betragMwst,
      betragNetto: this.betraege.betragNetto
    }
  }
}
