import { ApolloClient } from 'apollo-client'
import { ApolloLink } from 'apollo-link'
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory'
import { createHttpLink } from 'apollo-link-http'
import VueApollo from 'vue-apollo'
import Vue from "vue";
import introspectionQueryResultData from '@/generated/fragment-matcher.json';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { omitTypenameLink } from './omitTypenameLink'
import store from "../store/index";

Vue.use(VueApollo);

const headersMiddleware = new ApolloLink((operation, forward) => {
    const headers: Record<string, string> = {
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest'
    }

    const jwt = store.state.benutzer.jwt
    if (jwt) {
        headers['Authorization'] = `Bearer ${jwt}`
    }

    operation.setContext({
        headers
    })

    return forward(operation)
})

const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
})

const cache = new InMemoryCache({ fragmentMatcher })

const httpLink = createHttpLink({
    uri: '/graphql',
})

const apolloClient = new ApolloClient({
    link: ApolloLink.from([omitTypenameLink, headersMiddleware, httpLink]),
    cache,
})

export default new VueApollo({
    defaultClient: apolloClient
})