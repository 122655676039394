
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import { rulesRequiredPositiveNumber } from '@/application/Validators'
import { TopfDetailsFragment, TopfQueryVariables, useTopfQuery } from '@/generated/graphql'

@Component({
  apollo: {
    topf: useTopfQuery<ArbeitsleistungEdit>({
      variables(): TopfQueryVariables {
        if (this.topfId) {
          return {
            id: this.topfId
          }
        } else {
          throw new Error("no topfId")
        }
      },
      skip(): boolean {
        return !this.topfId
      }
    })
  }
})
export default class ArbeitsleistungEdit extends Vue {

  @Prop({ required: true })
  readonly arbeitsleistung!: ArbeitsleistungForm

  @Prop({ required: false })
  readonly topfId!: string | null

  @Prop({ required: false, default: false })
  readonly auftragsbasiert!: boolean

  @Prop({ required: false, default: false })
  readonly disabled!: boolean

  @Prop({ required: true })
  readonly anzahlStundenRules!: InputValidationRules

  @Prop({ required: true })
  readonly externeLeistung!: boolean

  topf: TopfDetailsFragment | undefined

  readonly rulesRequiredPositiveNumber: InputValidationRules = rulesRequiredPositiveNumber

  get disableStundensatz(): boolean {
    return this.disabled || !this.externeLeistung || this.auftragsbasiert
  }

  onAnzahlStundenChanged(value: string): void {
      this.$emit('update:arbeitsleistung', {
        ...this.arbeitsleistung,
        anzahlStunden: value,
        betrag: (Number.parseFloat(value) * Number.parseFloat(this.arbeitsleistung.stundensatz)).toFixed(2)
      } as ArbeitsleistungForm)
  }

  onStundensatzChanged(value: string): void {
    this.$emit('update:arbeitsleistung', {
      ...this.arbeitsleistung,
      stundensatz: value,
      betrag: (Number.parseFloat(value) * Number.parseFloat(this.arbeitsleistung.anzahlStunden)).toFixed(2)
    } as ArbeitsleistungForm)
  }

  onBetragChanged(value: string): void {
    this.$emit('update:arbeitsleistung', {
      ...this.arbeitsleistung,
      anzahlStunden: '1',
      stundensatz: value,
      betrag: (Number.parseFloat(value) * Number.parseFloat(this.arbeitsleistung.anzahlStunden)).toFixed(2)
    } as ArbeitsleistungForm)
  }

  onBezeichnungChanged(value: string): void {
    this.$emit('update:arbeitsleistung', {
      ...this.arbeitsleistung,
      bezeichnung: value
    } as ArbeitsleistungForm)
  }

  get hatTopfBudget(): boolean {
    return this.topfId && this.topf ? parseFloat(this.topf.kontostand.verfuegbar) > 0.0 : true
  }

  get hatTopfBudgetFurBetrag(): boolean {
    return this.topfId && this.topf ? parseFloat(this.topf.kontostand.verfuegbar) >= parseFloat(this.arbeitsleistung.betrag) : true
  }

}

export type ArbeitsleistungForm = {
  index: number;
  anzahlStunden: string;
  stundensatz: string;
  betrag: string;
  bezeichnung?: string | null;
  auftragsPositionId?: string | null;
};
