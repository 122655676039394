
import Component from 'vue-class-component'
import { Vue } from 'vue-property-decorator'

@Component({
    metaInfo: { title: 'Fehler' }
  })
  export default class ErrorPage extends Vue {

    get error(): string | null {
      return this.$route.query['error'] as string
    }

    back(): void {
      this.$router.back()
    }
  }
