import { render, staticRenderFns } from "./LoadingComponent.vue?vue&type=template&id=9aab4858&scoped=true&"
import script from "./LoadingComponent.vue?vue&type=script&lang=ts&"
export * from "./LoadingComponent.vue?vue&type=script&lang=ts&"
import style0 from "./LoadingComponent.vue?vue&type=style&index=0&id=9aab4858&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9aab4858",
  null
  
)

export default component.exports