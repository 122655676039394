
import { Component, Prop, Vue } from 'vue-property-decorator'
import FilePreview from '@/components/files/FilePreview.vue'

@Component({
  components: { FilePreview }
})
export default class FileSelectComponent extends Vue {
  readonly EVENT_NAME = 'file-selected'
  readonly EVENT_NAME_CONTENT = 'file-content'

  loading = false

  @Prop({ default: false })
  readonly disabled!: boolean

  @Prop()
  readonly filePreviewUrl!: string | null

  @Prop()
  readonly filePreviewContentType!: string | null

  @Prop()
  readonly filePreviewContent!: string | null

  @Prop({ default: 'primary' })
  readonly color!: string

  @Prop({ default: false })
  readonly largeButton!: boolean

  dragover = false
  selectedFile: File | null = null
  fileContents: string | null = null

  onDrop(event: DragEvent): void {
    this.dragover = false

    const transfer = event.dataTransfer as DataTransfer

    if (transfer.files.length === 0) {
      return
    }

    if (transfer.files.length > 1) {
      console.error('Jeder nur 1 Kreuz')
      return
    }

    this.uploadFile(transfer.files.item(0) as File)
  }

  btnClick(): void {
    // Forward click to hidden input-field
    const fileInputElement = this.$refs.fileInput as HTMLInputElement
    fileInputElement.click()
  }

  clear(): void {
    this.fileContents = null
    this.$emit(this.EVENT_NAME, undefined)
    this.$emit(this.EVENT_NAME_CONTENT, undefined)
  }

  async fileChange(event: Event): Promise<void> {
    if (!event.target) {
      return
    }

    this.selectedFile = null

    const eventTarget = event.target as HTMLInputElement

    if (!eventTarget.files || eventTarget.files.length === 0) {
      // No file selected
      this.$emit(this.EVENT_NAME, undefined)
      return
    }

    await this.uploadFile(eventTarget.files[0])
  }

  async uploadFile(file: File): Promise<void> {
    // Load file and emit it
    this.loading = true
    try {
      this.selectedFile = file
      this.fileContents = await this.readFileToMemory(this.selectedFile)
      this.$emit(this.EVENT_NAME, this.selectedFile)
      this.$emit(this.EVENT_NAME_CONTENT, this.fileContents)
    } catch (error) {
      console.error('Failed to load image', error)
    }
    this.loading = false
  }

  private readFileToMemory(file: File): Promise<string | null> {
    return new Promise<string | null>((resolve, reject) => {
      try {
        const reader = new FileReader()
        reader.onload = (event: ProgressEvent<FileReader>): void => {
          if (event.target) {
            resolve(event.target.result as string)
          } else {
            reject(new Error('FileReader failed to load file'))
          }
        }
        reader.readAsDataURL(file)
      } catch (err) {
        resolve(null)
      }
    })
  }
}
