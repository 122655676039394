
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import {
  AllKundenQuery,
  Auftrag,
  Kontakt,
  Kunde,
  KundenauftraegeQuery,
  KundenauftraegeQueryVariables,
  KundendatenInput,
  KundenkontakteQuery,
  KundenkontakteQueryVariables,
  LabeledIdentifiable, useAllKundenQuery, useKundenauftraegeQuery, useKundenkontakteQuery
} from '@/generated/graphql'

@Component({
  apollo: {
    moeglicheKunden: useAllKundenQuery<KundendatenEdit>({
      update(data: AllKundenQuery): LabeledIdentifiable[] {
        return data.kunden
      }
    }),
    moeglicheAuftraege: useKundenauftraegeQuery<KundendatenEdit>({
      variables(): KundenauftraegeQueryVariables {
        if (this.kundendaten?.kundeId) {
          return {
            kundeId: this.kundendaten.kundeId
          }
        } else {
          throw new Error("no kundeId")
        }
      },
      update(data: KundenauftraegeQuery): LabeledIdentifiable[] {
        return data.kundenauftraege
      },
      skip(): boolean {
        return !this.kundendaten.kundeId
      }
    }),
    moeglicheKontakte: useKundenkontakteQuery<KundendatenEdit>({
      variables(): KundenkontakteQueryVariables {
        if (this.kundendaten?.kundeId) {
          return {
            kundeId: this.kundendaten.kundeId
          }
        } else {
          throw new Error("no kundeId")
        }
      },
      update(data: KundenkontakteQuery): LabeledIdentifiable[] {
        return data.kundenkontakte
      },
      skip(): boolean {
        return !this.kundendaten.kundeId
      }
    })
  }
})
export default class KundendatenEdit extends Vue {

  @Prop({ required: true })
  readonly kundendaten!: KundendatenInput

  @Prop({ required: false, default: [] })
  readonly rulesKunde!: InputValidationRules

  @Prop({ required: false, default: false })
  readonly disabled!: boolean

  moeglicheKunden: Array<Kunde> = []
  moeglicheAuftraege: Array<Auftrag> = []
  moeglicheKontakte: Array<Kontakt> = []

  onAuftragChanged(newAuftragId?: string): void {
    const selectedAuftrag = this.moeglicheAuftraege.find(value => value.id === newAuftragId)
    this.$emit('update:auftrag', selectedAuftrag)

    const updated: KundendatenInput = {
      ...this.kundendaten,
      auftragId: newAuftragId
    }
    this.$emit('update:kundendaten', updated)
  }

  onKundeChanged(value?: string): void {
    const updated: KundendatenInput = {
      kundeId: value,
      auftragId: null,
      kontaktId: null
    }
    this.$emit('update:kundendaten', updated)
  }

  onKontaktChanged(value?: string): void {
    const updated: KundendatenInput = {
      ...this.kundendaten,
      kontaktId: value
    }
    this.$emit('update:kundendaten', updated)
  }

  kundeErfassen(): void {
    this.$router.push({
      name: 'kunden-id',
      params: {},
      query: { create: 'true' }
    })
  }

}
