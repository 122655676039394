
import PhotoImg from '@/components/PhotoImg.vue'
import { ApolloError } from 'apollo-client'
import { DocumentNode } from 'graphql'
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import {fetchAsObjectUrlAndOpenInNewWindow, uploadBeleg} from '@/application/RestApi'
import { notNegativeNumber, required, rulesRequiredNotNegativeNumber, rulesRequiredPositiveNumber } from '@/application/Validators'
import BetraegeComponent from '@/components/beteiligungen/BetraegeComponent.vue'
import ErrorBanner, { ErrorData } from '@/components/ErrorBanner.vue'
import FilePreview from '@/components/files/FilePreview.vue'
import FileSelectComponent from '@/components/files/FileSelectComponent.vue'
import DateField from '@/components/forms/DateField.vue'
import EditDialogAction from '@/components/forms/EditDialogAction.vue'
import EditDialogCard from '@/components/forms/EditDialogCard.vue'
import ArbeitsleistungEdit, { ArbeitsleistungForm } from '@/components/leistungen/ArbeitsleistungEdit.vue'
import ErbringerChip from '@/components/leistungen/ErbringerChip.vue'
import KundendatenEdit from '@/components/leistungen/KundendatenEdit.vue'
import {
  Auftrag, KundendatenInput, LeistungFreigebenDocument, LeistungInput,
  LeistungQuery,
  LeistungsArt, LeistungStornierenDocument,
  LeistungStornierenMutation,
  LeistungStornierenMutationVariables, LeistungVerbuchenDocument,
  MemberFieldsFragment,
  MembersQuery,
  QueryLeistungArgs,
  ToepfeQuery,
  Topf,
  TopfFieldsFragment,
  TopfStatus,
  TransaktionStatus, UpsertLeistungDocument, useLeistungQuery, useMembersQuery, useToepfeQuery, VerpflegungInput
} from '@/generated/graphql'

@Component({
  components: {
    PhotoImg,
    ErrorBanner,
    ErbringerChip,
    KundendatenEdit,
    ArbeitsleistungEdit,
    DateField,
    EditDialogAction,
    EditDialogCard,
    BetraegeComponent,
    FileSelectComponent,
    FilePreview
  },
  apollo: {
    leistung: useLeistungQuery<LeistungEdit>({
      variables(): QueryLeistungArgs {
        if (this.leistungId) {
          return {
            id: this.leistungId
          }
        } else {
          throw new Error("no leistungId")
        }
      },
      skip(): boolean {
        return !this.leistungId // Only do this query, if we have an ID
      },
      update(data: LeistungQuery): LeistungForm {
        const result = data.leistung
        this.erfordertKeinenBeleg = data.leistung.belegId === null
        return {
          id: result.id,
          version: result.version,
          datum: result.datum,
          bezeichnung: result.bezeichnung,
          notizen: result.notizen,
          erbringerId: result.erbringerId,
          arbeitsleistungen: result.arbeitsleistungen.map((value, index) => ({ ...value, index })),
          verpflegung: result.verpflegung,
          kundendaten: {
            kundeId: result.kundendaten?.kundeId,
            kontaktId: result.kundendaten?.kontaktId,
            auftragId: result.kundendaten?.auftragId
          },
          topfId: result.topfId,
          leistungsArt: result.leistungsArt,
          belegUrl: result.belegUrl,
          belegContentType: result.belegContentType,
          belegId: result.belegId,
          status: result.status,
          rechnungUrl: result.rechnungUrl,
          isWeiterverrechnung: result.isWeiterverrechnung || false
        }
      }
    }),
    moeglicheErbringer: useMembersQuery<LeistungEdit>({
      update(data: MembersQuery): MemberFieldsFragment[] {
        return data.members
      }
    }),
    moeglicheToepfe: useToepfeQuery<LeistungEdit>({
      update(data: ToepfeQuery): TopfFieldsFragment[] {
        const isTopfFromThisYear = (datum: string): boolean => new Date(Date.parse(datum)).getFullYear() === new Date().getFullYear()
        return data.toepfe.filter(t => ((t.status === TopfStatus.Offen && isTopfFromThisYear(t.datum)) || t.id === this.leistung.topfId))
      }
    })
  }
})
export default class LeistungEdit extends Vue {
  // Form Validation
  formValid = false
  readonly rulesRequired: InputValidationRules = [required]
  readonly rulesRequiredNotNegative: InputValidationRules = [required, notNegativeNumber]

  @Prop({
    required: false,
    default: null
  })
  readonly leistungId!: string | null

  leistungsArten = [LeistungsArt.Extern, LeistungsArt.Intern]
  erfordertKeinenBeleg = false

  moeglicheErbringer: Array<MemberFieldsFragment> = []
  moeglicheToepfe: Array<TopfFieldsFragment> = []

  // Form Data
  leistung: LeistungForm = {
    id: null,
    version: null,
    bezeichnung: '',
    notizen: '',
    erbringerId: this.$store.state.benutzer.memberId,
    kundendaten: {},
    arbeitsleistungen: [{
      index: 0,
      bezeichnung: '',
      anzahlStunden: '0',
      stundensatz: '0',
      betrag: '0'
    }],
    topfId: null,
    verpflegung: {
      abendessen: '0',
      fruehstueck: '0',
      mittagessen: '0',
      schaltstelleBuero: '0'
    },
    leistungsArt: LeistungsArt.Extern,
    datum: (new Date()).toISOString().substring(0, 10),
    belegUrl: null,
    belegContentType: null,
    status: TransaktionStatus.InBearbeitung,
    rechnungUrl: null,
    belegId: null,
    isWeiterverrechnung: false
  }

  beleg: File | null = null
  imageContent: string | null = null
  loading = 0

  errorData: ErrorData | null = null

  get auftragsbasiert(): boolean {
    return this.isExtern && !!this.leistung.kundendaten?.auftragId
  }

  get anzahlStundenRules(): InputValidationRules {
    return this.leistung.arbeitsleistungen.length > 1 ? rulesRequiredNotNegativeNumber : rulesRequiredPositiveNumber
  }

  get showSpeichernAction(): boolean {
    if (this.leistung && this.leistung.status !== TransaktionStatus.Storniert) {
      if (this.leistung.status === TransaktionStatus.Ok) {
        return this.$store.state.benutzer.isBuchhalter
      }

      return true
    }

    return false
  }

  get showFreigebenAction(): boolean {
    return this.leistung.status === TransaktionStatus.InBearbeitung
  }

  get showRechnungErstellenAction(): boolean {
    return this.leistung.status === TransaktionStatus.ZuVerbuchen && this.$store.state.benutzer.isBuchhalter
  }

  get showStornierenAction(): boolean {
    if (this.leistung && this.leistung.version && this.leistung.status !== TransaktionStatus.Storniert) {
      if (this.leistung.status === TransaktionStatus.Ok) {
        return this.$store.state.benutzer.isBuchhalter
      }

      return true
    }

    return false
  }

  get isRechnungErstellenActionDisabled(): boolean {
    return !this.formValid || (!!this.leistung.kundendaten && !this.leistung.kundendaten.kundeId)
  }

  get rulesKunde(): InputValidationRules {
    return this.leistung.status === TransaktionStatus.ZuVerbuchen && this.isExtern ? this.rulesRequired : []
  }

  get useNotizenForCustomerData(): boolean {
    if (this.isExtern) {
      return !!this.leistung.kundendaten && !this.leistung.kundendaten.kundeId
    } else {
      return false
    }
  }

  get isExtern(): boolean {
    return this.leistung.leistungsArt === LeistungsArt.Extern
  }

  get isIntern(): boolean {
    return this.leistung.leistungsArt === LeistungsArt.Intern
  }

  onAuftragChanged(auftrag?: Auftrag): void {
    if (auftrag) {
      if (auftrag.positionen.length > 0) {
        this.leistung.arbeitsleistungen = auftrag.positionen.map((value, index) => ({
          index,
          bezeichnung: value.bezeichnung,
          stundensatz: value.preisProEinheit,
          anzahlStunden: '0',
          betrag: '0',
          auftragsPositionId: value.id
        }))
      } else {
        this.leistung.arbeitsleistungen = [
          {
            index: 0,
            stundensatz: '0',
            anzahlStunden: '0',
            betrag: '0'
          }
        ]
      }
    }
  }

  fileSelected(beleg: File | null): void {
    this.beleg = beleg
    this.leistung.belegContentType = beleg?.type
  }

  saveLeistung(): void {
    setTimeout(() => {
      return this.mutate(UpsertLeistungDocument)
    }, 100)
  }

  freigeben(): void {
    setTimeout(() => {
      this.mutate(LeistungFreigebenDocument)
    }, 100)
  }

  alsVerbuchtMarkieren(): void {
    setTimeout(() => {
      this.mutate(LeistungVerbuchenDocument)
    }, 100)
  }

  isClosed = (t: Topf): boolean => t.status === TopfStatus.Geschlossen

  private async mutate(mutation: DocumentNode): Promise<void> {
    try {
      this.loading += 1

      let belegId = null
      if (this.beleg) {
        belegId = await uploadBeleg(this.beleg)
      } else if (!this.erfordertKeinenBeleg) {
        belegId = this.leistung.belegId
      }

      const input: LeistungInput = {
        id: this.leistung.id,
        version: this.leistung.version,
        datum: this.leistung.datum,
        erbringerId: this.leistung.erbringerId,
        kundendaten: this.isExtern ? this.leistung.kundendaten : null,
        topfId: this.leistung.topfId,
        leistungsArt: this.leistung.leistungsArt,
        arbeitsleistungen: this.leistung.arbeitsleistungen.map(value => ({
          auftragsPositionId: value.auftragsPositionId,
          bezeichnung: value.bezeichnung,
          stundensatz: value.stundensatz,
          anzahlStunden: value.anzahlStunden
        })),
        verpflegung: this.leistung.verpflegung,
        notizen: this.leistung.notizen,
        bezeichnung: this.leistung.bezeichnung,
        isWeiterverrechnung: this.leistung.isWeiterverrechnung,
        belegId
      }

      await this.$apollo.mutate({
        mutation,
        variables: {
          input
        }
      })

      await this.$apollo.queries.leistung.refetch()
      this.$emit('completed')
    } catch (e) {
      if (e instanceof ApolloError) {
        const graphQLError = e.graphQLErrors[0]
        this.errorData = {
          code: graphQLError?.extensions?.code,
          message: graphQLError?.message
        }
      } else {
        throw e
      }
    } finally {
      this.loading -= 1
    }

  }

  stornieren(): void {
    if (!this.leistung?.version || !this.leistung?.id) {
      return
    }

    const input = {
      id: this.leistung.id,
      version: this.leistung.version
    }
    setTimeout(() => {
      this.stornierenRequest(input)
    }, 100)
  }

  private async stornierenRequest(input: { id: string; version: string }): Promise<void> {
    try {
      this.loading += 1

      await this.$apollo.mutate<LeistungStornierenMutation, LeistungStornierenMutationVariables>({
        mutation: LeistungStornierenDocument,
        variables: { input }
      })

      this.$emit('completed')
    } catch (e) {
      if (e instanceof ApolloError) {
        const graphQLError = e.graphQLErrors[0]
        this.errorData = {
          code: graphQLError?.extensions?.code,
          message: graphQLError?.message
        }
      } else {
        throw e
      }
    } finally {
      this.loading -= 1
    }
  }

  cancel(): void {
    this.$emit('cancelled')
  }

  refresh(): void {
    this.errorData = null
    this.$apollo.queries.leistung.refetch()
  }

  @Watch('leistung.leistungsArt', { immediate: true })
  setStundensatz(): void {
    if (this.isIntern) {
      this.leistung.arbeitsleistungen.forEach((value) => {
        value.stundensatz = '100'
      })
    }
  }

  get isDisabled(): boolean {
    return this.leistung?.status !== TransaktionStatus.InBearbeitung && !this.$store.state.benutzer.isBuchhalter
  }

  get erfordertBeleg(): boolean {
    return !this.erfordertKeinenBeleg
  }

  get hasBeleg(): boolean {
    return this.beleg !== null || this.leistung.belegId !== null
  }

  get share(): string | undefined {
    return this.leistungId ? `/leistungen/${this.leistungId}` : undefined
  }

  async openRechnungPdf() {
    if (this.leistung.rechnungUrl) {
      await fetchAsObjectUrlAndOpenInNewWindow("Rechnung", this.leistung.rechnungUrl)
    }
  }
}

export type LeistungForm = {
  id?: string | null;
  version?: string | null;
  datum: string;
  erbringerId: string;
  kundendaten?: KundendatenInput | null;
  topfId?: string | null;
  leistungsArt?: LeistungsArt | null;
  arbeitsleistungen: Array<ArbeitsleistungForm>;
  verpflegung: VerpflegungInput;
  notizen: string;
  bezeichnung?: string | null;
  belegId?: string | null;
  isWeiterverrechnung?: boolean;
  belegUrl?: string | null;
  belegContentType?: string | null;
  status: TransaktionStatus;
  rechnungUrl?: string | null;
};
