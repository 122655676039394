

import { Component, Vue } from 'vue-property-decorator'
import {
  ErfolgsrechnungDetailByMemberIdAndYearQueryVariables,
  ErfolgsrechnungDetailSheet,
  ErfolgsrechnungenQueryVariables,
  ErfolgsrechnungFieldsFragment, useErfolgsrechnungDetailByMemberIdAndYearQuery,
  useErfolgsrechnungenQuery
} from '@/generated/graphql'

@Component({
  apollo: {
    erfolgsrechnungen: useErfolgsrechnungenQuery<MembersPage>({
      variables(): ErfolgsrechnungenQueryVariables {
        return {
          year: this.selectedYear.toString()
        }
      }
    }),
    erfolgsrechnungDetailByMemberIdAndYear: useErfolgsrechnungDetailByMemberIdAndYearQuery<MembersPage>({
      variables(): ErfolgsrechnungDetailByMemberIdAndYearQueryVariables {
        if (this.memberId) {
          return {
            id: this.memberId,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error("no memberId")
        }
      },
      skip(): boolean {
        return !this.memberId || !this.selectedYear
      },
      result({ data, loading }): void {
        if (loading) {
          return
        }
        let csv = this.memberName + ',,\n'
        const ret = data.erfolgsrechnungDetailByMemberIdAndYear as ErfolgsrechnungDetailSheet
        ret.data.forEach((e) => {
          if (e) {
            csv = csv + e.key + ',,\n'
            let total = 0
            e.entries.forEach((b) => {
              if (b) {
                total = total + parseFloat(b.betrag)
                csv = csv + b.valuta + ',"' + b.bezeichnung + '",' + b.betrag + '\n'
              }
            })
            csv = csv + ',Total,' + total + '\n'
          }
        })
        const anchor = document.createElement('a')
        anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csv)
        anchor.target = '_blank'
        anchor.download = 'Erfolgsrechnung_Detail_' + this.memberName + '.csv'
        anchor.click()
        this.memberId = null
      }
    })
  }
})
export default class MembersPage extends Vue {
  memberId: string | null = null
  memberName: string | null = null

  erfolgsrechnungen: ErfolgsrechnungFieldsFragment[] = []
  erfolgsrechnungDetailByMemberIdAndYear: ErfolgsrechnungDetailSheet | undefined

  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  getSaldoClasses(betrag: string): string {
    return Number.parseInt(betrag) < 0 ? 'red lighten-5' : 'green lighten-5'
  }

  refresh(): void {
    this.$apollo.queries.erfolgsrechnungen.refetch()
    this.$apollo.queries.erfolgsrechnungDetailByMemberIdAndYear.refetch()
  }

  loadDetail(memberId: string, memberName: string): void {
    this.memberName = memberName
    this.memberId = memberId
    this.$apollo.queries.erfolgsrechnungDetailByMemberIdAndYear.refetch()
  }

}
