
import GoogleLogin from '@/components/GoogleLogin.vue'
import { BenutzerFieldsFragment, MeDocument, MeQuery, MeQueryVariables } from '@/generated/graphql'
import store from '@/store'
import { ApolloQueryResult } from 'apollo-client'
import jwtDecode, { JwtPayload } from 'jwt-decode'
import { Component, Vue, Watch } from 'vue-property-decorator'

@Component({
  components: { GoogleLogin }
})
export default class LoadingComponent extends Vue {

  error = false
  loading = false

  // noinspection JSUnusedGlobalSymbols
  async mounted () {

    if (!store.getters.hasUserData && this.isFormLogin) {
      await this.loadUserData()
    }
  }

  async loadUserData() {
    try {
      this.loading = true
      const meQuery: ApolloQueryResult<MeQuery> = await this.$apollo.query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        variables: {
          year: store.state.benutzer.selectedYear
        }
      })
      this.loading = false

      if (meQuery.errors || !meQuery.data.me) {
        if (this.isFormLogin) {
          this.redirectToFormLogin()
        } else {
          console.error('Cannot get user info ' + meQuery.errors)
          this.error = true
          this.loading = false
        }
      } else {
        this.updateUserDataInStore(meQuery.data.me)
      }
    } catch (e) {
      if (this.isFormLogin) {
        this.redirectToFormLogin()
      } else {
        console.error('Cannot get user info ' + e)
        this.error = true
        this.loading = false
      }
    }
  }

  @Watch("$store.state.benutzer.jwt")
  loadUserDataWithJwt(jwt: string) {
    if (jwt && this.hasValidAccessToken) {
      this.loadUserData()
    }
  }

  redirectToFormLogin() {
    window.location.href = `${window.location.origin}/login`;
  }

  get selectedYear() {
    return this.$store.state.benutzer.selectedYear
  }

  updateUserDataInStore(me: BenutzerFieldsFragment) {
    this.$store.commit('update', me)
    this.$store.dispatch('updatePhoto')
  }

  get showGoogleLogin(): boolean {
    return !this.isFormLogin && !this.hasValidAccessToken
  }

  get hasValidAccessToken(): boolean {
    const tokenString = this.$store.state.benutzer.jwt

    let result = false
    if (tokenString) {
      const token = jwtDecode<JwtPayload>(tokenString)
      if (token.exp) {
        result = Date.now() < token.exp * 1000
      }
    }

    return result
  }

  get isFormLogin(): boolean {
    return window.location.hostname === 'localhost' || window.location.hostname === 'appdemo.schaltstelle.ch'
  }
}
