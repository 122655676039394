import { render, staticRenderFns } from "./LeistungenPage.vue?vue&type=template&id=40ad3458&scoped=true&"
import script from "./LeistungenPage.vue?vue&type=script&lang=ts&"
export * from "./LeistungenPage.vue?vue&type=script&lang=ts&"
import style0 from "./LeistungenPage.vue?vue&type=style&index=0&id=40ad3458&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40ad3458",
  null
  
)

export default component.exports