
import { Component, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import { positiveNumber } from '@/application/Validators'
import { AuszahlungInput, AuszahlungStornierenDocument, AuszahlungsTyp, Member, MembersSpesenQueryVariables, UpsertAuszahlungDocument, useMembersBonusQuery } from '@/generated/graphql'

@Component({
  apollo: {
    members: useMembersBonusQuery<BonusPage>({
      variables(): MembersSpesenQueryVariables {
        return {
          year: this.selectedYear.toString()
        }
      }
    })
  }
})
export default class BonusPage extends Vue {

  readonly positiveNumber: InputValidationRules = [positiveNumber]
  members: Member[] | null = null
  expanded = []

  auszahlungsBetraege: {
    [key: string]: string;
  } = {}

  headers = [
    {
      text: 'Member',
      align: 'start',
      divider: true,
      sortable: true,
      value: 'name'
    },
    {
      text: 'Bonus Netto',
      value: 'lohndaten.bonusNetto'
    },
    {
      text: 'Ausbezahlt',
      value: 'bonus.ausbezahlt'
    },
    {
      text: 'Verbleibend',
      value: 'bonus.verbleibend'
    },
    {
      text: 'Auszahlen',
      divider: true,
      value: 'actions',
      sortable: false
    },
    {
      text: 'Details',
      value: 'data-table-expand'
    }
  ]

  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  editPermitted = this.$store.state.benutzer.isBuchhalter

  async addAuszahlung(empfaenger: string): Promise<void> {
    if (parseFloat(this.auszahlungsBetraege[empfaenger]) > 0) {
      const input: AuszahlungInput = {
        betrag: this.auszahlungsBetraege[empfaenger],
        typ: AuszahlungsTyp.Bonus,
        empfaenger,
        datum: new Date(this.selectedYear, 11, 31).toISOString().substring(0, 10)
      }
      await this.$apollo.mutate({
        mutation: UpsertAuszahlungDocument,
        variables: {
          input
        }
      })
      delete this.auszahlungsBetraege[empfaenger]
      this.$apollo.queries.members.refetch()
    }
  }

  async deleteAuszahlung(id: string, version: number): Promise<void> {
    await this.$apollo.mutate({
      mutation: AuszahlungStornierenDocument,
      variables: {
        input: {
          id,
          version
        }
      }
    })
    this.$apollo.queries.members.refetch()
  }

}
