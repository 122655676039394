
import { TableModel } from '@/application/TablesSupport'
import BeteiligungenChip from '@/components/beteiligungen/BeteiligungenChip.vue'
import TransaktionStatusIcon from '@/components/TransaktionStatusIcon.vue'
import UebertragEdit from '@/components/uebertraege/UebertragEdit.vue'
import {
  MemberFieldsFragment,
  MembersQuery,
  TransaktionStatus,
  UebertraegeByKontoIdAndStatusPagedQuery,
  UebertraegeByKontoIdAndStatusPagedQueryVariables,
  UebertragFieldsFragment, useMembersQuery,
  useUebertraegeByKontoIdAndStatusPagedQuery
} from '@/generated/graphql'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'

const pageTitle = 'Überträge'

@Component({
  components: {
    TransaktionStatusIcon,
    UebertragEdit,
    BeteiligungenChip
  },
  metaInfo: {
    title: pageTitle
  },
  apollo: {
    uebertraegeByKontoIdAndStatusPaged: useUebertraegeByKontoIdAndStatusPagedQuery<UebertraegePage>({
      variables() {
        const defaultStatus = [TransaktionStatus.InBearbeitung, TransaktionStatus.Ok, TransaktionStatus.ZuVerbuchen, TransaktionStatus.Storniert]

        const newVar: UebertraegeByKontoIdAndStatusPagedQueryVariables = {
          memberId: this.filterMember === this.allObj.id ? '' : this.filterMember,
          statusIn: this.filterStatus === this.all ? defaultStatus : [this.filterStatus],
          year: this.selectedYear.toString(),
          page: this.tableModel.pageable.page?.toString() ?? '0',
          size: this.tableModel.pageable.size?.toString() ?? '0',
          sort: this.tableModel.pageable.sort
        }
        return newVar
      },
      result({ data, loading }): void {
        if (loading) {
          return
        }
        const typedData = data as UebertraegeByKontoIdAndStatusPagedQuery
        const items = typedData.uebertraegeByKontoIdAndStatusPaged.content
        this.betragTotal = items.map(x => parseFloat(x.betrag)).reduce((acc, cur) => acc + cur, 0)

        this.tableModel.update(items, typedData.uebertraegeByKontoIdAndStatusPaged.totalElements, typedData.uebertraegeByKontoIdAndStatusPaged.totalPages.toString())
      }
    }),
    filterMembers: useMembersQuery<UebertraegePage>({
      update(data: MembersQuery): MemberFieldsFragment[] {
        data.members.unshift(this.allObj)
        return data.members
      }
    })
  }
})
export default class UebertraegePage extends Vue {

  // noinspection JSUnusedGlobalSymbols
  get selectedYear(): number {
    return Number(this.$store.state.benutzer.selectedYear)
  }

  get rowsPerPage(): number {
    return Number(this.$store.state.benutzer.rowsPerPage)
  }

  public readonly tableModel = new TableModel<UebertragFieldsFragment>([
    {
      text: 'Datum',
      value: 'datum'
    },
    {
      text: 'Bezeichnung',
      value: 'bezeichnung'
    },
    {
      text: 'Betrag',
      value: 'betrag',
      align: 'end'
    },
    {
      text: 'Von',
      value: 'von',
      sortable: false
    },
    {
      text: 'Nach',
      value: 'nach',
      sortable: false
    },
    {
      text: 'Status',
      value: 'status',
      sortable: false
    },
    {
      text: '',
      value: 'actions',
      sortable: false
    }
  ], ['datum', 'bezeichnung'], [true, false], this.rowsPerPage)

  showEditDialog = false
  selectedId: string | null = null
  betragTotal = 0

  // Filter
  isMember = (): boolean => !!this.$store.state.benutzer.memberId

  all = 'Alle'
  allObj = { name: this.all, id: this.all }

  filterMembers: Array<MemberFieldsFragment> = []
  filterMember = this.isMember() ? this.$store.state.benutzer.memberId : this.allObj.id

  filterStatusAuswahl = [this.all, TransaktionStatus.InBearbeitung, TransaktionStatus.Ok, TransaktionStatus.Storniert, TransaktionStatus.ZuVerbuchen]
  filterStatus = this.isMember() ? this.all : TransaktionStatus.ZuVerbuchen

  @Watch('$route', { immediate: true })
  routeChange(): void {
    const id = this.$route.params.id
    if (id) {
      this.editItem(id)
    } else if ('create' in this.$route.query) {
      this.editItem(null)
    } else {
      this.selectedId = null
      this.showEditDialog = false
    }
  }

  createItem(): void {
    this.editItem(null)
  }

  editItem(id: string | null): void {
    this.selectedId = id
    this.switchEditDialog(true)
  }

  switchEditDialog(show: boolean): void {
    this.showEditDialog = show
  }

  onCompletedEdit(): void {
    this.$apollo.queries.uebertraegeByKontoIdAndStatusPaged.refetch()
    this.switchEditDialog(false)
  }

  get tableHeaders(): DataTableHeader[] {
    return this.$vuetify.breakpoint.lgAndUp ? this.tableModel.headers : this.tableModel.headers.filter(value => !value.hideIfMobile)
  }

  pageSizeChanged(rowsPerPage: number): void {
    this.$store.commit('updateRowsPerPage', rowsPerPage)
  }
}
