
import { ApolloError } from 'apollo-client'
import { MutationResult } from 'vue-apollo-smart-ops'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import { mapBeteiligungToBeteiligungInput } from '@/application/GraphQLUtils'
import { rulesRequired } from '@/application/Validators'
import BeteiligungenSelectionComponent from '@/components/beteiligungen/BeteiligungenSelectionComponent.vue'
import BetraegeComponent from '@/components/beteiligungen/BetraegeComponent.vue'
import ErrorBanner, { ErrorData } from '@/components/ErrorBanner.vue'
import DateField from '@/components/forms/DateField.vue'
import EditDialogAction from '@/components/forms/EditDialogAction.vue'
import EditDialogCard from '@/components/forms/EditDialogCard.vue'
import {
  CreateUebertragMutation, createUebertragMutation,
  KontoFieldsFragment,
  KontosByTypeQuery,
  KontosByTypeQueryVariables,
  KontoTyp, StorniereUebertragMutation, storniereUebertragMutation,
  TransaktionStatus, UebertragCreateInput,
  UebertragFieldsFragment,
  UebertragQueryVariables,
  UebertragUpdateInput, updateUebertragMutation,
  UpdateUebertragMutation,
  useKontosByTypeQuery, useUebertragQuery
} from '@/generated/graphql'

function mapUebertragToUebertragUpdateInput(uebertrag: UebertragFieldsFragment): UebertragUpdateInput {
  return {
    id: uebertrag.id,
    version: uebertrag.version,
    datum: uebertrag.datum,
    bezeichnung: uebertrag.bezeichnung,
    betrag: uebertrag.betrag,
    von: uebertrag.von.map(mapBeteiligungToBeteiligungInput),
    nach: uebertrag.nach.map(mapBeteiligungToBeteiligungInput)
  }
}

function mapUebertragToUebertragCreateInput(uebertrag: UebertragFieldsFragment): UebertragCreateInput {
  return {
    datum: uebertrag.datum,
    bezeichnung: uebertrag.bezeichnung,
    betrag: uebertrag.betrag,
    von: uebertrag.von.map(mapBeteiligungToBeteiligungInput),
    nach: uebertrag.nach.map(mapBeteiligungToBeteiligungInput)
  }
}

@Component({
  components: {
    ErrorBanner,
    DateField,
    EditDialogAction,
    EditDialogCard,
    BetraegeComponent,
    BeteiligungenSelectionComponent
  },
  apollo: {
    uebertrag: useUebertragQuery<UebertragEdit>({
      variables(): UebertragQueryVariables {
        if (this.uebertragId) {
          return {
            id: this.uebertragId
          }
        } else {
          throw new Error('no uebertragId')
        }
      },
      skip(): boolean {
        return !this.uebertragId
      }
    }),
    moeglicheVon: useKontosByTypeQuery<UebertragEdit>({
      variables(): KontosByTypeQueryVariables {
        return {
          type: KontoTyp.Guthabenkonto
        }
      },
      update(data: KontosByTypeQuery): KontoFieldsFragment[] {
        return data.konten
      }
    }),
    moeglicheNach: useKontosByTypeQuery<UebertragEdit>({
      variables(): KontosByTypeQueryVariables {
        return {
          type: KontoTyp.Guthabenkonto
        }
      },
      update(data: KontosByTypeQuery): KontoFieldsFragment[] {
        return data.konten
      }
    })
  }
})
export default class UebertragEdit extends Vue {

  @Prop({
    required: false,
    default: null
  })
  readonly uebertragId!: string | null

  @Prop({
    required: false,
    default: null
  })
  readonly parentUebertragTemplate!: UebertragFieldsFragment | null

  // noinspection JSUnusedGlobalSymbols
  mounted() {
    if (this.parentUebertragTemplate) {
      this.uebertrag = this.parentUebertragTemplate
    }
  }

  // Form Data
  uebertrag: UebertragFieldsFragment = {
    id: '',
    status: TransaktionStatus.InBearbeitung,
    version: '0',
    bezeichnung: '',
    betrag: '0',
    datum: (new Date()).toISOString().substring(0, 10),
    von: [],
    nach: []
  }

  loading = 0

  moeglicheVon: KontoFieldsFragment[] = []
  moeglicheNach: KontoFieldsFragment[] = []

  // Form Validation
  formValid = false
  readonly rulesRequired: InputValidationRules = rulesRequired

  errorData: ErrorData | null = null

  get isCreateMode(): boolean {
    return !this.uebertragId
  }

  get isUebertragValid(): boolean {
    return !!this.uebertrag
  }

  get isStatusOk(): boolean {
    return this.uebertrag?.status === TransaktionStatus.Ok
  }

  upsert(): Promise<void> {
    if (this.isCreateMode) {
      return this.mutate(createUebertragMutation(this, {
        variables: {
          input: mapUebertragToUebertragCreateInput(this.uebertrag)
        }
      }))
    } else {
      return this.mutate(updateUebertragMutation(this, {
        variables: {
          input: mapUebertragToUebertragUpdateInput(this.uebertrag)
        }
      }))
    }
  }

  stornieren(): Promise<void> {
    return this.mutate(storniereUebertragMutation(this, {
        variables: { id: this.uebertrag.id }
      })
    )
  }

  private async mutate(mutation: Promise<MutationResult<CreateUebertragMutation | UpdateUebertragMutation | StorniereUebertragMutation>>) {
    try {
      this.loading += 1
      await mutation
      this.$emit('completed')
    } catch (e) {
      if (e instanceof ApolloError) {
        const graphQLError = e.graphQLErrors[0]
        this.errorData = {
          code: graphQLError?.extensions?.code,
          message: graphQLError?.message
        }
      } else {
        throw e
      }
    } finally {
      this.loading -= 1
    }
  }

  cancel(): void {
    this.$emit('cancelled')
  }

  refresh(): void {
    this.errorData = null
    this.$apollo.queries.uebertrag.refetch()
  }

  get share(): string | undefined {
    return this.uebertragId ? `/uebertraege/${this.uebertragId}` : undefined
  }
}
