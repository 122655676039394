import { InputValidationRule, InputValidationRules } from 'vuetify'

export const required: InputValidationRule = function(v: string | null | undefined | []): boolean | string {
  return (!!v && (('' + v).length > 0)) || 'Wird benötigt'
}

export const positiveNumber: InputValidationRule = function(v: string | null | undefined): boolean | string {
  if (v) {
    try {
      const num = Number.parseFloat(v)
      if (num <= 0) {
        return 'Muss positiv sein'
      }
    } catch (e) {
      return 'Muss eine Zahl sein'
    }
  }
  return true
}

export const notNegativeNumber: InputValidationRule = function(v: string | null | undefined): boolean | string {
  if (v) {
    try {
      const num = Number.parseFloat(v)
      if (num < 0) {
        return 'Darf nicht negativ sein'
      }
    } catch (e) {
      return 'Muss eine Zahl sein'
    }
  }
  return true
}

export const rulesRequired: InputValidationRules = [required]
export const rulesRequiredPositiveNumber: InputValidationRules = [required, positiveNumber]
export const rulesRequiredNotNegativeNumber: InputValidationRules = [required, notNegativeNumber]
