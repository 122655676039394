
import { MobileAwareDataTableHeader, TableModel } from '@/application/TablesSupport'
import AusgabeEdit from '@/components/ausgaben/AusgabeEdit.vue'
import BeteiligungenChip from '@/components/beteiligungen/BeteiligungenChip.vue'
import PhotoImg from '@/components/PhotoImg.vue'
import TransaktionStatusIcon from '@/components/TransaktionStatusIcon.vue'
import {
  AusgabenByKontoIdAndStatusPagedQuery,
  AusgabenByKontoIdAndStatusPagedQueryVariables,
  KontoFieldsFragment,
  KontosByTypeAndYearQuery,
  KontosByTypeAndYearQueryVariables,
  KontoTyp,
  MemberKontenQuery,
  MemberKontenQueryVariables,
  TransaktionStatus,
  useAusgabenByKontoIdAndStatusPagedQuery,
  useKontosByTypeAndYearQuery,
  useMemberKontenQuery
} from '@/generated/graphql'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'

const pageTitle = 'Ausgaben'

type Filter = {
  id: string;
  name: string;
  icon: string;
}

@Component({
  components: {
    PhotoImg,
    TransaktionStatusIcon,
    AusgabeEdit,
    BeteiligungenChip
  },
  metaInfo: { title: pageTitle },
  apollo: {
    ausgabenByKontoIdAndStatusPaged: useAusgabenByKontoIdAndStatusPagedQuery<AusgabenPage>({
      skip(): boolean {
        return !this.filterBezahler
      },
      variables(): AusgabenByKontoIdAndStatusPagedQueryVariables {
        const defaultStatus = [TransaktionStatus.InBearbeitung, TransaktionStatus.Ok, TransaktionStatus.ZuVerbuchen, TransaktionStatus.Storniert]
        return {
          kostentraeger: this.filterKostentraeger === this.all ? '' : this.filterKostentraeger,
          kostentraegerExakt: this.filterKostentraegerExakt,
          bezahler: this.filterBezahler === this.all ? '' : this.filterBezahler,
          bezahlerExakt: this.filterBezahlerExakt,
          statusIn: this.filterStatus === this.all ? defaultStatus : [this.filterStatus],
          verpflegungsSpesenAusLeistungenEinblenden: this.verpflegungsSpesenAusLeistungenEinblenden,
          year: this.selectedYear.toString(),
          page: this.tableModel.pageable.page?.toString() ?? '0',
          size: this.tableModel.pageable.size?.toString() ?? '0',
          sort: this.tableModel.pageable.sort
        }
      },
      result({
        data,
        loading
      }): void {
        if (loading) {
          return
        }
        const typedData = data as AusgabenByKontoIdAndStatusPagedQuery
        this.betraegeTotal.brutto = typedData.ausgabenByKontoIdAndStatusPaged.content.map(x => parseFloat(x.betraege.betragBrutto)).reduce((acc, cur) => acc + cur, 0)
        this.betraegeTotal.netto = typedData.ausgabenByKontoIdAndStatusPaged.content.map(x => parseFloat(x.betraege.betragNetto)).reduce((acc, cur) => acc + cur, 0)
        this.betraegeTotal.mwst = typedData.ausgabenByKontoIdAndStatusPaged.content.map(x => parseFloat(x.betraege.betragMwst)).reduce((acc, cur) => acc + cur, 0)
        this.tableModel.update(typedData.ausgabenByKontoIdAndStatusPaged.content, typedData.ausgabenByKontoIdAndStatusPaged.totalElements, typedData.ausgabenByKontoIdAndStatusPaged.totalPages)
      }
    }),
    moeglicheKostentraeger: useKontosByTypeAndYearQuery<AusgabenPage>({
      variables(): KontosByTypeAndYearQueryVariables {
        return {
          type: KontoTyp.Guthabenkonto,
          year: this.selectedYear.toString()
        }
      },
      result({ data, loading }): void {
        if (loading) { return }
        const typedData = data as KontosByTypeAndYearQuery
        this.filterKostentraegerAuswahl = [{
              id: 'Alle',
              name: 'Alle',
              icon: require('@/assets/logo.png')
            }]
        typedData.kontosByTypeAndYear.forEach((m) => {
          this.filterKostentraegerAuswahl.push({
            id: m.id,
            name: m.bezeichnung,
            icon: m.iconUrl || require('@/assets/logo.png')
          })
        })
      },
      update(data: KontosByTypeAndYearQuery): KontoFieldsFragment[] {
        return data.kontosByTypeAndYear
      }
    }),
    moeglicheBezahler: useKontosByTypeAndYearQuery<AusgabenPage>({
      variables(): KontosByTypeAndYearQueryVariables {
        return {
          type: KontoTyp.Spesenkonto,
          year: this.selectedYear.toString()
        }
      },
      result({ data, loading }): void {
        if (loading) { return }
        const typedData = data as KontosByTypeAndYearQuery
        this.filterBezahlerAuswahl = [{
          id: 'Alle',
          name: 'Alle',
          icon: require('@/assets/logo.png')
        }]
        typedData.kontosByTypeAndYear
            .forEach((m) => {
              this.filterBezahlerAuswahl.push({
                id: m.id,
                name: m.bezeichnung,
                icon: m.iconUrl || require('@/assets/logo.png')
            })
        })
      },
      update(data: KontosByTypeAndYearQuery): KontoFieldsFragment[] {
        return data.kontosByTypeAndYear
      }
    }),
    member: useMemberKontenQuery<AusgabenPage>({
      skip(): boolean {
        return !this.$store.state.benutzer.memberId
      },
      variables(): MemberKontenQueryVariables {
        return {
          id: this.$store.state.benutzer.memberId
        }
      },
      result({ data, loading }): void {
        if (loading) { return }
        const typedData = data as MemberKontenQuery
        // this.filterBezahler = typedData.member.spesenkonto.id
        this.filterKostentraeger = typedData.member.guthabenkonto.id
      }
    })
  }
})
export default class AusgabenPage extends Vue {

  get selectedYear(): number {
    return Number(this.$store.state.benutzer.selectedYear)
  }

  get rowsPerPage(): number {
    return Number(this.$store.state.benutzer.rowsPerPage)
  }

  readonly tableModel = new TableModel<unknown>([
    {
      text: 'Datum',
      value: 'datum'
    },
    {
      text: 'Bezeichnung',
      value: 'bezeichnung'
    },
    {
      text: 'Betrag',
      value: 'betraege.betragBrutto'
    },
    {
      text: 'Netto',
      value: 'betraege.betragNetto'
    },
    {
      text: 'Bezahler',
      value: 'bezahler'
    },
    {
      text: 'Kostenträger',
      value: 'kostentraeger'
    },
    {
      text: 'Notizen',
      value: 'notizen',
      hideIfMobile: true
    },
    {
      text: 'Status',
      value: 'status',
      sortable: false
    },
    {
      text: 'Wiederholung',
      value: 'wiederholung',
      sortable: false
    },
    {
      text: '',
      value: 'actions',
      sortable: false
    }
  ], ['datum', 'bezeichnung'], [true, false], this.rowsPerPage)

  showEditDialog = false
  selectedId: string | null = null

  betraegeTotal = {
    brutto: 0,
    netto: 0,
    mwst: 0
  }

  // Filter
  isMember = (): boolean => !!this.$store.state.benutzer.memberId
  all = 'Alle'

  filterKostentraegerAuswahl: Array<Filter> = [{
    id: 'Alle',
    name: 'Alle',
    icon: require('@/assets/logo.png')
  }]

  filterBezahlerAuswahl: Array<Filter> = [{
    id: 'Alle',
    name: 'Alle',
    icon: require('@/assets/logo.png')
  }]

  filterBezahler = this.all
  filterKostentraeger = this.all

  filterBezahlerExakt = false
  filterKostentraegerExakt = false
  verpflegungsSpesenAusLeistungenEinblenden = false

  filterStatusAuswahl = [this.all, TransaktionStatus.InBearbeitung, TransaktionStatus.Ok, TransaktionStatus.Storniert, TransaktionStatus.ZuVerbuchen]
  filterStatus = this.isMember() ? this.all : TransaktionStatus.ZuVerbuchen

  @Watch('$route', { immediate: true })
  routeChange(): void {
    const id = this.$route.params.id
    if (id) {
      this.editItem(id)
    } else if ('create' in this.$route.query) {
      this.editItem(null)
    } else {
      this.selectedId = null
      this.showEditDialog = false
    }
  }

  get tableHeaders(): DataTableHeader[] {
    return this.$vuetify.breakpoint.lgAndUp ? this.tableModel.headers : this.tableModel.headers.filter((value: MobileAwareDataTableHeader) => !value.hideIfMobile)
  }

  createItem(): void {
    this.editItem(null)
  }

  editItem(id: string | null): void {
    if (id && this.isAusgabeVerpflegungAusLeistung(id)) { return }
    this.selectedId = id
    this.switchEditDialog(true)
  }

  switchEditDialog(show: boolean): void {
    this.showEditDialog = show
  }

  onCompletedEdit(): void {
    this.refresh()
    this.switchEditDialog(false)
  }

  isEditableStatus(status: TransaktionStatus, parentId: string): boolean {
    return status === TransaktionStatus.InBearbeitung || !!parentId
  }

  pageSizeChanged(rowsPerPage: number): void {
    this.$store.commit('updateRowsPerPage', rowsPerPage)
  }

  refresh(): void {
    this.$apollo.queries.ausgabenByKontoIdAndStatusPaged.refetch()
  }

  isAusgabeVerpflegungAusLeistung = (id: string): boolean => id.includes('verflegungspesenausleistung')

  background = (id: string): string => this.isAusgabeVerpflegungAusLeistung(id) ? 'blue lighten-5' : ''

}
