import { fetchAsObjectUrl } from '@/application/RestApi'
import { Benutzer } from '@/generated/graphql'
import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store<RootState>({
  state: {
    nav: {
      drawer: null
    },
    benutzer: {
      id: '',
      email: '',
      name: '',
      photoUrl: '',
      isMember: false,
      isBuchhalter: false,
      memberId: '',
      selectedYear: localStorage.getItem('selectedYear') ?? '-1',
      selectedMember: localStorage.getItem('selectedMember') ?? '-1',
      rowsPerPage: localStorage.getItem('rowsPerPage') ?? '15',
      jwt: ''
    }
  },
  getters: {
    hasUserData(state): boolean {
      return !!state.benutzer.name
    }
  },
  mutations: {
    setDrawer(state, value: boolean | null) {
      state.nav.drawer = value
    },
    toggleDrawer(state) {
      state.nav.drawer = !state.nav.drawer
    },
    update(state, benutzer: Benutzer) {
      state.benutzer.id = benutzer.id
      state.benutzer.name = benutzer.name
      state.benutzer.email = benutzer.email
      state.benutzer.isMember = benutzer.isMember
      state.benutzer.isBuchhalter = benutzer.isBuchhalter
      state.benutzer.memberId = benutzer.member?.id || ''
    },
    updatePhoto(state, photoUrl: string) {
      state.benutzer.photoUrl = photoUrl
    },
    updateAccessToken(state, jwt: string) {
      state.benutzer.jwt = jwt
    },
    updateSelectedYear(state, year: string) {
      state.benutzer.selectedYear = year
      localStorage.setItem('selectedYear', year)
    },
    updateSelectedMember(state, memberId: string) {
      state.benutzer.selectedMember = memberId
      localStorage.setItem('selectedMember', memberId)
    },
    updateRowsPerPage(state, rowsPerPage: string) {
      state.benutzer.rowsPerPage = rowsPerPage
      localStorage.setItem('rowsPerPage', rowsPerPage)
    }
  },
  actions: {
    async updatePhoto({ commit, state }) {
      if (this.state.benutzer.id) {
        const photoUrl = await fetchAsObjectUrl(`/api/benutzer/${state.benutzer.id}/photo`)
        commit('updatePhoto', photoUrl)
      }
    }
  },
  modules: {
  }
})

export type BenutzerState = {
  id: string
  name: string
  email: string
  photoUrl: string
  isMember: boolean
  isBuchhalter: boolean
  memberId: string
  selectedYear: string
  rowsPerPage: string
  selectedMember: string
  jwt: string
}

export interface RootState {
  benutzer: BenutzerState
  nav: NavState
}

export type NavState = {
  drawer: boolean | null
}