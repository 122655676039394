
import LoadingComponent from '@/components/LoadingComponent.vue'
import { Component, Vue } from 'vue-property-decorator'
import SchaltappBar from '@/components/SchaltappBar.vue'

@Component({
  metaInfo: {
    title: 'Schaltapp',
    titleTemplate: '%s | Schaltapp'
  },
  components: { LoadingComponent, SchaltappBar }
})
export default class AppFrame extends Vue {

  get drawer(): boolean | null {
    return this.$store.state.nav.drawer
  }

  set drawer(value: boolean | null) {
    this.$store.commit('setDrawer', value)
  }

  get avatar(): string {
    return this.$store.state.benutzer.photoUrl
  }

  get isLoggedIn(): boolean {
    return this.$store.state.benutzer.name
  }

  get username(): string {
    return this.$store.state.benutzer.name
  }

  private readonly allItems = [
    {
      icon: 'mdi-chevron-up',
      'icon-alt': 'mdi-chevron-down',
      text: 'Member',
      expanded: true,
      children: [
        {
          icon: 'mdi-chart-tree',
          text: 'Dashboard',
          route: 'dashboard',
          exact: true
        },
        {
          icon: 'mdi-cash-multiple',
          text: 'Lohn',
          route: 'lohn'
        },
        {
          icon: 'mdi-bank-transfer-out',
          text: 'Ausgaben',
          route: 'ausgaben-id'
        },
        {
          icon: 'mdi-bank-transfer-in',
          text: 'Leistungen',
          route: 'leistungen-id'
        },
        {
          icon: 'mdi-transfer',
          text: 'Überträge',
          route: 'uebertraege-id'
        },
        {
          icon: new Date().getHours() === 12 ? 'mdi-pot-steam' : 'mdi-pot', // Homage to Nidi's Sibad easteregg
          text: 'Töpfe',
          route: 'toepfe'
        },
        {
          icon: 'mdi-account-multiple',
          text: 'Members',
          route: 'admin-members'
        },
        {
          icon: 'mdi-office-building-outline',
          text: 'Kunden',
          route: 'kunden-id'
        }
      ]
    },
    {
      icon: 'mdi-chevron-up',
      'icon-alt': 'mdi-chevron-down',
      text: 'Admin',
      expanded: false,
      children: [
        {
          icon: 'mdi-cash',
          text: 'Löhne',
          route: 'admin-loehne'
        },
        {
          icon: 'mdi-account-cash',
          text: 'Spesen',
          route: 'admin-spesen'
        },
        {
          icon: 'mdi-palm-tree',
          text: 'Bonus',
          route: 'admin-bonus'
        }
      ]
    }
  ]
}
