

import { TableModel } from '@/application/TablesSupport'
import ErbringerChip from '@/components/leistungen/ErbringerChip.vue'
import LeistungEdit from '@/components/leistungen/LeistungEdit.vue'
import PhotoImg from '@/components/PhotoImg.vue'
import TransaktionStatusIcon from '@/components/TransaktionStatusIcon.vue'
import {
  Leistung,
  LeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery,
  LeistungenByMemberIdAndStatusAndLeistungsArtPagedQueryVariables,
  LeistungenPagedContentFragment,
  LeistungsArt,
  LeistungsBexioStatus,
  MemberFieldsFragment,
  MembersQuery,
  TransaktionStatus,
  useLeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery, useMembersQuery
} from '@/generated/graphql'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import {fetchAsObjectUrlAndOpenInNewWindow} from "@/application/RestApi";

const pageTitle = 'Leistungen'

export type LeistungenPagedContentFragmentWithBexio = ({ bexioStatus: LeistungsBexioStatus } & LeistungenPagedContentFragment)

@Component({
  components: {
    PhotoImg,
    TransaktionStatusIcon,
    ErbringerChip,
    LeistungEdit
  },
  metaInfo: { title: pageTitle },
  apollo: {
    leistungenByMemberIdAndStatusAndLeistungsArtPaged: useLeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery<LeistungenPage>({
      variables(): LeistungenByMemberIdAndStatusAndLeistungsArtPagedQueryVariables {
        const defaultStatus = [TransaktionStatus.InBearbeitung, TransaktionStatus.Ok, TransaktionStatus.ZuVerbuchen, TransaktionStatus.Storniert]

        return {
          memberId: this.filterMember === this.all ? '' : this.filterMember,
          leistungsArt: this.filterLeistungsArt !== this.all ? this.filterLeistungsArt as LeistungsArt : null,
          statusIn: this.filterStatus === this.all ? defaultStatus : [this.filterStatus],
          year: this.selectedYear.toString(),
          page: this.tableModel.pageable.page?.toString() ?? '0',
          size: this.tableModel.pageable.size?.toString() ?? '0',
          sort: this.tableModel.pageable.sort
        }
      },
      result({
        data,
        loading
      }): void {
        if (loading) {
          return
        }
        const typedData = data as LeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery
        this.betragTotal = typedData.leistungenByMemberIdAndStatusAndLeistungsArtPaged.content.filter(x => x.status !== TransaktionStatus.Storniert).map(x => parseFloat(x.betragTotal)).reduce((acc, cur) => acc + cur, 0)
        this.tableModel.update(typedData.leistungenByMemberIdAndStatusAndLeistungsArtPaged.content, typedData.leistungenByMemberIdAndStatusAndLeistungsArtPaged.totalElements, typedData.leistungenByMemberIdAndStatusAndLeistungsArtPaged.totalPages)
      }
    }),
    filterMembers: useMembersQuery<LeistungenPage>({
      update(data: MembersQuery): MemberFieldsFragment[] {
        data.members.unshift({ name: this.all, id: this.all })
        return data.members
      }
    })
  }
})
export default class LeistungenPage extends Vue {
  // noinspection JSUnusedGlobalSymbols
  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  readonly tableModel = new TableModel<LeistungenPagedContentFragmentWithBexio>([
    {
      text: 'Datum',
      value: 'datum'
    },
    {
      text: 'Erbringer',
      value: 'erbringer'
    },
    {
      text: 'Leistungs Art',
      value: 'leistungsArt'
    },
    {
      text: 'Kunde',
      value: 'kunde'
    },
    {
      text: 'Bezeichnung',
      value: 'bezeichnung'
    },
    {
      text: 'Betrag',
      value: 'betrag',
      align: 'end'
    },
    {
      text: 'Status',
      value: 'status',
      sortable: false
    },
    {
      text: 'Rechnung',
      value: 'rechnungUrl',
      sortable: false
    },
    {
      text: 'Bexio Status',
      value: 'bexioStatus',
      sortable: false
    },
    {
      text: '',
      value: 'actions',
      sortable: false
    }
  ], ['datum', 'bezeichnung'], [true, false])

  showEditDialog = false
  selectedId: string | null = null
  betragTotal = 0

  // Filter
  isMember = (): boolean => !!this.$store.state.benutzer.memberId
  all = 'Alle'

  filterMembers: Array<MemberFieldsFragment> = []
  filterMember = this.isMember() ? this.$store.state.benutzer.memberId : this.all

  filterStatusAuswahl = [this.all, TransaktionStatus.InBearbeitung, TransaktionStatus.Ok, TransaktionStatus.Storniert, TransaktionStatus.ZuVerbuchen]
  filterStatus = this.isMember() ? this.all : TransaktionStatus.ZuVerbuchen

  filterLeistungsArtAuswahl = [this.all, LeistungsArt.Intern, LeistungsArt.Extern]
  filterLeistungsArt = this.all

  @Watch('$route', { immediate: true })
  routeChange(): void {
    const id = this.$route.params.id
    if (id) {
      this.editItem(id)
    } else if ('create' in this.$route.query) {
      this.editItem(null)
    } else {
      this.selectedId = null
      this.showEditDialog = false
    }
  }

  get tableHeaders(): DataTableHeader[] {
    return this.$vuetify.breakpoint.lgAndUp ? this.tableModel.headers : this.tableModel.headers.filter(value => !value.hideIfMobile)
  }

  createItem(): void {
    this.editItem(null)
  }

  editItem(id: string | null): void {
    this.selectedId = id
    this.switchEditDialog(true)
  }

  getColor = (bexioStatus: LeistungsBexioStatus): string => {
    switch (bexioStatus) {
      case LeistungsBexioStatus.Offen:
        return 'cyan'
      case LeistungsBexioStatus.Bezahlt:
        return 'green'
      case LeistungsBexioStatus.Teilweise:
        return 'orange'
      case LeistungsBexioStatus.Unbezahlt:
        return 'red'
      default:
        return 'grey'
    }
  }

  isValidStatus = (s: LeistungsBexioStatus): boolean => s !== LeistungsBexioStatus.Unbekannt

  switchEditDialog(show: boolean): void {
    this.showEditDialog = show
  }

  onCompletedEdit(): void {
    this.$apollo.queries.leistungenByMemberIdAndStatusAndLeistungsArtPaged.refetch()
    this.switchEditDialog(false)
  }

  getKundenname(item: Leistung): string {
    if (this.isExtern(item)) {
      return item.kundendaten && item.kundendaten.kunde && item.kundendaten.kunde.bezeichnung ? item.kundendaten.kunde.bezeichnung : ''
    } else {
      return item.topf && item.topf.bezeichnung ? item.topf.bezeichnung : ''
    }
  }

  isExtern(item: Leistung): boolean {
    return item.leistungsArt === LeistungsArt.Extern
  }

  isEditableStatus(status: TransaktionStatus): boolean {
    return status === TransaktionStatus.InBearbeitung
  }

  async openRechnungPdf(url?: string) {
    if (url) {
      fetchAsObjectUrlAndOpenInNewWindow("Rechnung", url)
    }
  }
}
