
import { ApolloError } from 'apollo-client'
import { DocumentNode } from 'graphql'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import { rulesRequired } from '@/application/Validators'
import BeteiligungenSelectionComponent from '@/components/beteiligungen/BeteiligungenSelectionComponent.vue'
import BetraegeComponent from '@/components/beteiligungen/BetraegeComponent.vue'
import ErrorBanner, { ErrorData } from '@/components/ErrorBanner.vue'
import FileSelectComponent from '@/components/files/FileSelectComponent.vue'
import DateField from '@/components/forms/DateField.vue'
import EditDialogAction from '@/components/forms/EditDialogAction.vue'
import EditDialogCard from '@/components/forms/EditDialogCard.vue'
import { CreateKundeDocument, CreateKundeMutationVariables, Kunde, KundeQueryVariables, UpdateKundeDocument, UpdateKundeMutationVariables, useKundeQuery } from '@/generated/graphql'

@Component({
  components: {
    ErrorBanner,
    DateField,
    EditDialogAction,
    EditDialogCard,
    BetraegeComponent,
    FileSelectComponent,
    BeteiligungenSelectionComponent
  },
  apollo: {
    kunde: useKundeQuery<KundeEdit>({
      variables(): KundeQueryVariables {
        if (this.kundeId) {
          return {
            id: this.kundeId
          }
        } else {
          throw new Error("no kundeId")
        }
      },
      skip(): boolean {
        return !this.kundeId // Only do this query, if we have an ID
      }
    })
  }
})
export default class KundeEdit extends Vue {

  @Prop({
    required: false,
    default: null
  })
  readonly kundeId!: string | null

  // Form Data
  kunde: Kunde = { id: '', bezeichnung: '', kontakte: [], auftraege: [] }

  image: File | null = null
  imageContent: string | null = null

  loading = 0

  // Form Validation
  formValid = false
  readonly rulesRequired: InputValidationRules = rulesRequired

  errorData: ErrorData | null = null

  upsert(): Promise<void> {
    if (!this.kundeId) {
      return this.mutate(CreateKundeDocument, {
         input: {
            bezeichnung: this.kunde.bezeichnung,
            strasse: this.kunde.strasse,
            plz: this.kunde.plz,
            stadt: this.kunde.stadt,
            email: this.kunde.email
          }
        })
    } else {
      return this.mutate(UpdateKundeDocument, {
        input: {
          id: this.kundeId,
          bezeichnung: this.kunde.bezeichnung,
          strasse: this.kunde.strasse,
          plz: this.kunde.plz,
          stadt: this.kunde.stadt,
          email: this.kunde.email
        }
      })
    }
  }

  private async mutate(mutation: DocumentNode, variables: UpdateKundeMutationVariables | CreateKundeMutationVariables): Promise<void> {
    if (!this.kunde) {
      return
    }
    try {
      this.loading += 1
      await this.$apollo.mutate<UpdateKundeMutationVariables | CreateKundeMutationVariables>({
        mutation,
        variables
      })
      this.$emit('completed')
    } catch (e) {
      if (e instanceof ApolloError) {
        const graphQLError = e.graphQLErrors[0]
        this.errorData = {
          code: graphQLError?.extensions?.code,
          message: graphQLError?.message
        }
      } else {
        throw e
      }
    } finally {
      this.loading -= 1
    }

  }

  cancel(): void {
    this.$emit('cancelled')
  }

  refresh(): void {
    this.errorData = null
    this.$apollo.queries.kunde.refetch()
  }
}
