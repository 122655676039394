
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Topf, TopfOeffnenDocument, TopfStatus, TopfStimme, TopfStimmeAbgebenDocument, TopfStimmeInput } from '@/generated/graphql'

@Component
export default class TopfCard extends Vue {
  @Prop({
    required: true
  })
  readonly topf!: Topf

  // noinspection JSUnusedGlobalSymbols
  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  stimme: TopfStimmeInput = {
    memberId: this.$store.state.benutzer.memberId,
    veto: false
  }

  isClosed = (t: Topf): boolean => t.status === TopfStatus.Geschlossen
  isActive = (t: Topf): boolean => t.status === TopfStatus.Offen
  isRequested = (t: Topf): boolean => t.status === TopfStatus.Antrag
  isRejected = (t: Topf): boolean => t.status === TopfStatus.Abgelehnt

  daysLeft = (t: Topf): string => {
    const daysForVeto = 7
    const diff = (new Date().getTime() - Date.parse(t.datum))
    const daysPassedSinceCreated = diff / (1000 * 60 * 60 * 24)
    return `${daysForVeto - Math.floor(daysPassedSinceCreated)} Tage`
  }

  showDetail(id: string): void {
    this.$router.push({
      name: 'toepfe-id',
      params: { id }
    })
  }

  edit(id: string): void {
    this.$router.push({
      name: 'toepfe',
      params: { id },
      query: { update: 'true' }
    })
  }

  async forceOpen(id: string): Promise<void> {
    await this.$apollo.mutate({
      mutation: TopfOeffnenDocument,
      variables: { id }
    })
  }

  async stimmeAbgeben(veto: false): Promise<void> {
    if (this.topf && this.topf.id) {
      this.stimme.veto = veto
      await this.$apollo.mutate({
        mutation: TopfStimmeAbgebenDocument,
        variables: {
          id: this.topf.id,
          stimme: this.stimme
        }
      })
    }
  }

  printNames = (stimmen: TopfStimme[], veto: boolean): string => stimmen.filter(s => s.veto === veto).map(s => s.member?.name).join(', ')

}
