
import { Component, Vue, Watch } from 'vue-property-decorator'
import TopfCard from '@/components/toepfe/TopfCard.vue'
import TopfCreate from '@/components/toepfe/TopfCreate.vue'
import { ToepfeQueryVariables, Topf, TopfStatus, useToepfeQuery } from '@/generated/graphql'

const pageTitle = 'Töpfe'

@Component({
  components: { TopfCard, TopfCreate },
  metaInfo: { title: pageTitle },
  apollo: {
    toepfe: useToepfeQuery<TopfPage>({
      variables(): ToepfeQueryVariables {
        return {
          year: this.selectedYear.toString()
        }
      }
    })
  }
})
export default class TopfPage extends Vue {

  // noinspection JSUnusedGlobalSymbols
  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  toepfe: Topf[] = []

  showEditDialog = false
  selectedId: string | null = null

  @Watch('$route', { immediate: true })
  routeChange(): void {
    if ('create' in this.$route.query) {
      this.selectedId = null
      this.showEditDialog = true
    } else if ('update' in this.$route.query && this.$route.params.id) {
      this.selectedId = this.$route.params.id
      this.showEditDialog = true
    } else if ('update' in this.$route.query && !this.$route.params.id) {
      this.cancelEdit()
    } else {
      this.selectedId = null
      this.showEditDialog = false
    }
  }

  createItem(): void {
    this.$router.push({
      name: 'toepfe',
      params: {},
      query: { create: 'true' }
    })
  }

  isClosed = (t: Topf): boolean => t.status === TopfStatus.Geschlossen
  isActive = (t: Topf): boolean => t.status === TopfStatus.Offen
  isRequested = (t: Topf): boolean => t.status === TopfStatus.Antrag
  isRejected = (t: Topf): boolean => t.status === TopfStatus.Abgelehnt

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleDuplicatedNavigation = (reason: any) => {
    if (reason.name !== 'NavigationDuplicated') {
      throw reason
    }
  }

  @Watch('showEditDialog')
  handleDialogClosing(): void {
    if (!this.showEditDialog) {
      this.$router.push({ name: 'toepfe' }).catch(this.handleDuplicatedNavigation)
    }
  }

  cancelEdit(): void {
    this.$router.push({ name: 'toepfe' }).catch(this.handleDuplicatedNavigation)
  }

  onCompletedEdit(): void {
    this.$apollo.queries.toepfe.refetch()
    this.$router.push({ name: 'toepfe' }).catch(this.handleDuplicatedNavigation)
  }
}
