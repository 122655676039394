import { render, staticRenderFns } from "./TopfCard.vue?vue&type=template&id=25e5e224&scoped=true&"
import script from "./TopfCard.vue?vue&type=script&lang=ts&"
export * from "./TopfCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25e5e224",
  null
  
)

export default component.exports