
import { Component, Vue } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { TableModel } from '@/application/TablesSupport'
import { MAX_INT } from '@/scalars'
import {
  BuchungenPagedQuery,
  BuchungFieldsFragment,
  BuchungPage,
  Erfolgsrechnung,
  ErfolgsrechnungByMemberIdAndYearQuery,
  ErfolgsrechnungByMemberIdAndYearQueryVariables,
  ErfolgsrechnungFieldsFragment,
  Kontostand,
  LeistungenByMemberIdAndStatusAndLeistungsArtPagedQueryVariables,
  LeistungsArt,
  Member,
  MemberDashboardQueryVariables,
  MemberFieldsFragment,
  MembersQuery,
  QueryBuchungenPagedArgs,
  TransaktionStatus,
  useBuchungenPagedQuery,
  useErfolgsrechnungByMemberIdAndYearQuery,
  useLeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery,
  useMemberDashboardQuery,
  useMembersQuery,
  useVerbuchteLeistungenQuery,
  VerbuchteLeistungenQuery,
  VerbuchteLeistungenQueryVariables
} from '@/generated/graphql'

const pageTitle = 'Schaltapp'

type Verbucht = {
  vertrag: string;
  budget: number;
  rechnungen: number;
  leistungen: number;
  verbraucht: number;
  verfuegbar: number;
  progress: number;
  leistungsArt: LeistungsArt;
}

@Component({
  metaInfo: {
    title: pageTitle
  },
  apollo: {
    member: useMemberDashboardQuery<DashboardPage>({
      variables(): MemberDashboardQueryVariables {
        if (this.selectedMember) {
          return {
            id: this.selectedMember,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error('no selectedMember')
        }
      },
      skip(): boolean {
        return !this.selectedMember
      }
    }),
    erfolgsrechnung: useErfolgsrechnungByMemberIdAndYearQuery<DashboardPage>({
      variables(): ErfolgsrechnungByMemberIdAndYearQueryVariables {
        if (this.selectedMember) {
          return {
            id: this.selectedMember,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error('no selectedMember')
        }
      },
      skip(): boolean {
        return !this.selectedMember
      },
      update(data: ErfolgsrechnungByMemberIdAndYearQuery): ErfolgsrechnungFieldsFragment {
        return data.erfolgsrechnungByMemberIdAndYear
      }
    }),
    verbucht: useVerbuchteLeistungenQuery<DashboardPage>({
      variables(): VerbuchteLeistungenQueryVariables {
        if (this.selectedMember) {
          return {
            memberId: this.selectedMember,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error('no selectedMember')
        }
      },
      skip(): boolean {
        return !this.selectedMember
      },
      update(data: VerbuchteLeistungenQuery): Verbucht[] | undefined {
        return data.verbuchteLeistungen?.map((l) => {
              if (l.leistungsArt === LeistungsArt.Intern) {
                const progress = Math.floor(parseFloat(l.verbrauchtTopf) / (parseFloat(l.budgetTopf) / 100))
                return {
                  vertrag: l.topf ? l.topf.bezeichnung : '',
                  budget: parseFloat(l.budgetTopf),
                  rechnungen: parseFloat(l.rechnungen),
                  leistungen: parseFloat(l.leistungen),
                  verbraucht: parseFloat(l.verbrauchtTopf),
                  leistungsArt: l.leistungsArt,
                  verfuegbar: parseFloat(l.verfuegbarTopf),
                  progress
                }
              } else {
                const budget = parseFloat(l.kundendaten && l.kundendaten.auftrag ? l.kundendaten.auftrag.budget : '0')
                const verfuegbar = budget > 0 ? budget - parseFloat(l.leistungen) : 0.0
                const progress = budget > 0 ? Math.floor(parseFloat(l.leistungen) / (budget / 100)) : 0.0
                return {
                  vertrag: l.kundendaten && l.kundendaten.kunde ? l.kundendaten.kunde.bezeichnung : '',
                  budget,
                  rechnungen: parseFloat(l.rechnungen),
                  leistungen: parseFloat(l.leistungen),
                  verbraucht: parseFloat(l.leistungen),
                  leistungsArt: l.leistungsArt,
                  verfuegbar,
                  progress
                }
              }
            }
        )
      }
    }),
    members: useMembersQuery<DashboardPage>({
      update(data: MembersQuery): MemberFieldsFragment[] {
        return data.members
      }
    }),
    buchungenGuthabenPaged: useBuchungenPagedQuery<DashboardPage>({
      variables(): QueryBuchungenPagedArgs {
        if (this.member) {
          const pageSize = this.tableModelBuchungenGuthaben.pageable.size ?? -1
          return {
            kontoId: this.member.guthabenkonto.id,
            year: this.selectedYear.toString(),
            page: this.tableModelBuchungenGuthaben.pageable.page?.toString() ?? '0',
            sort: this.tableModelBuchungenGuthaben.pageable.sort,
            size: pageSize < 0 ? MAX_INT.toString() : pageSize.toString()
          }
        } else {
          throw new Error("no member")
        }
      },
      update(data: BuchungenPagedQuery): { __typename?: 'BuchungPage' } & Pick<BuchungPage, 'totalElements' | 'totalPages'> & { content: Array<({ __typename?: 'Buchung' } & BuchungFieldsFragment)> } {
        const typedData = data as BuchungenPagedQuery
        return typedData.buchungenPaged
      },
      result({
        data,
        loading
      }): void {
        if (loading) {
          return
        }
        const typedData = data as BuchungenPagedQuery
        this.betragGuthabenTotal = typedData.buchungenPaged.content.map(x => parseFloat(x.betrag)).reduce((acc, cur) => acc + cur, 0)
        this.tableModelBuchungenGuthaben.update(typedData.buchungenPaged.content, typedData.buchungenPaged.totalElements, typedData.buchungenPaged.totalPages)
      },
      skip(): boolean {
        return !this.member
      }
    }),
    buchungenSpesenPaged: useBuchungenPagedQuery<DashboardPage>({
      variables(): QueryBuchungenPagedArgs {

        if (this.member) {
          const pageSize = this.tableModelBuchungenSpesen.pageable.size ?? -1
          return {
            kontoId: this.member.spesenkonto.id,
            year: this.selectedYear.toString(),
            page: this.tableModelBuchungenSpesen.pageable.page?.toString() ?? '0',
            sort: this.tableModelBuchungenSpesen.pageable.sort,
            size: pageSize < 0 ? MAX_INT.toString() : pageSize.toString()
          }
        } else {
          throw new Error("no member")
        }
      },
      update(data: BuchungenPagedQuery): { __typename?: 'BuchungPage' } & Pick<BuchungPage, 'totalElements' | 'totalPages'> & { content: Array<({ __typename?: 'Buchung' } & BuchungFieldsFragment)> } {
        const typedData = data as BuchungenPagedQuery
        return typedData.buchungenPaged
      },
      result({
        data,
        loading
      }): void {
        if (loading) {
          return
        }
        const typedData = data as BuchungenPagedQuery
        this.betragSpesenTotal = typedData.buchungenPaged.content.map(x => parseFloat(x.betrag)).reduce((acc, cur) => acc + cur, 0)
        this.tableModelBuchungenSpesen.update(typedData.buchungenPaged.content, typedData.buchungenPaged.totalElements, typedData.buchungenPaged.totalPages)
      },
      skip(): boolean {
        return !this.member
      }
    }),
    leistungenByMemberIdAndStatusAndLeistungsArtPaged: useLeistungenByMemberIdAndStatusAndLeistungsArtPagedQuery<DashboardPage>({
      variables(): LeistungenByMemberIdAndStatusAndLeistungsArtPagedQueryVariables {
        if (this.selectedMember) {
          return {
            memberId: this.selectedMember,
            statusIn: [TransaktionStatus.Ok, TransaktionStatus.ZuVerbuchen],
            year: this.selectedYear.toString(),
            page: '0',
            size: MAX_INT.toString(),
            sort: ['datum,desc']
          }
        } else {
          throw new Error("no member")
        }
      }
    })
  }
})
export default class DashboardPage extends Vue {
  MAX_INT_IMPORT = MAX_INT
  betragGuthabenTotal = 0
  betragSpesenTotal = 0
  verbucht: Verbucht[] = []

  // noinspection JSUnusedGlobalSymbols
  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  public readonly tableModelVerbuchteLeistungen = new TableModel<Verbucht>([
    {
      text: 'Kunde',
      value: 'vertrag'
    },
    {
      text: 'Budget',
      value: 'budget'
    },
    {
      text: 'Verbraucht',
      value: 'leistungen'
    },
    {
      text: 'Verbraucht total',
      value: 'verbraucht'
    },
    {
      text: 'Verfügbar',
      value: 'verfuegbar'
    },
    {
      text: 'Progress',
      value: 'progress'
    }
  ], ['vertrag', 'budget', 'leistungen'], [true, true, true, false, false, false], MAX_INT)

  public readonly tableModelBuchungenGuthaben = new TableModel<BuchungFieldsFragment>([
    {
      text: 'Datum',
      value: 'valuta'
    },
    {
      text: 'Bezeichnung',
      value: 'bezeichnung'
    },
    {
      text: 'Betrag',
      value: 'betrag'
    }
  ], ['valuta', 'bezeichnung', 'betrag'], [true, true, true], 5)

  public readonly tableModelBuchungenSpesen = new TableModel<BuchungFieldsFragment>([
    {
      text: 'Datum',
      value: 'valuta'
    },
    {
      text: 'Bezeichnung',
      value: 'bezeichnung'
    },
    {
      text: 'Betrag',
      value: 'betrag'
    }
  ], ['valuta', 'bezeichnung', 'betrag'], [true, true, true], 5)

  get tableHeadersBuchungenGuthaben(): DataTableHeader[] {
    return this.$vuetify.breakpoint.lgAndUp ? this.tableModelBuchungenSpesen.headers : this.tableModelBuchungenSpesen.headers.filter(value => !value.hideIfMobile)
  }

  get tableHeadersBuchungenSpesen(): DataTableHeader[] {
    return this.$vuetify.breakpoint.lgAndUp ? this.tableModelBuchungenSpesen.headers : this.tableModelBuchungenSpesen.headers.filter(value => !value.hideIfMobile)
  }

  readonly avatar = this.$store.state.benutzer.photoUrl
  readonly isBuchhalter = this.$store.state.benutzer.isBuchhalter

  get isMyself(): boolean {
    return this.$store.state.benutzer.memberId === this.member?.id
  }

  get selectedMember(): string | null {
    if (this.isBuchhalter) {
      if (this.$store.state.benutzer.selectedMember && this.$store.state.benutzer.selectedMember !== '-1') {
        return this.$store.state.benutzer.selectedMember
      } else {
        this.member = null
        this.erfolgsrechnung = null
        return null
      }
    } else {
      return this.$store.state.benutzer.id
    }
  }

  members: Array<MemberFieldsFragment> = []

  member: Member | null = null
  erfolgsrechnung: Erfolgsrechnung | null = null

  get guthabenkontoKontostand(): Kontostand | undefined {
    return this.member?.guthabenkonto.kontostand
  }

  get spesenkontoKontostand(): Kontostand | undefined {
    return this.member?.spesenkonto.kontostand
  }

  get betragSuffix(): string | undefined {
    return this.$vuetify.breakpoint.smAndUp ? 'CHF' : undefined
  }

  filterIntern = (verbucht: Verbucht[]) => verbucht.filter(x => x.leistungsArt === LeistungsArt.Intern)
  filterExtern = (verbucht: Verbucht[]) => verbucht.filter(x => x.leistungsArt === LeistungsArt.Extern)

  getSaldoClasses = (betrag: string): string => Number.parseInt(betrag) < 0 ? 'red lighten-5' : 'green lighten-5'

}
