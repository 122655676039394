
import { Component, Vue } from 'vue-property-decorator'
import { MemberFieldsFragment, MembersQuery, useMembersQuery } from '@/generated/graphql'

const defaultAwesomeness = 7

@Component({
  apollo: {
    members: useMembersQuery<SchaltappBar>({
      update(data: MembersQuery): MemberFieldsFragment[] {
        return [{ id: this.memberId, name: 'Mein Profil' }, ...data.members]
      }
    })
  }
})
export default class SchaltappBar extends Vue {

  showBusinessplan = false
  readonly isMember = this.$store.state.benutzer.isMember
  readonly isBuchhalter = this.$store.state.benutzer.isBuchhalter
  readonly memberId = this.isBuchhalter && !this.isMember ? '-1' : this.$store.state.benutzer.id

  get selectedYears() {
    const currentYear = new Date().getFullYear()
    const ret = [{
      name: 'Ewig',
      value: '-1'
    }]
    for (let i = 2020; i <= currentYear; i++) {
      ret.push({
        name: `${i}`,
        value: `${i}`
      })
    }
    return ret
  }

  members: Array<MemberFieldsFragment> = []
  selectedYear: string = this.$store.state.benutzer.selectedYear

  selectedMember: string = this.isBuchhalter ? this.$store.state.benutzer.selectedMember : this.$store.state.benutzer.id

  private businessPlanCounter = defaultAwesomeness

  get avatar(): string {
    return this.$store.state.benutzer.photoUrl
  }

  get username(): string {
    return this.$store.state.benutzer.name
  }

  toggleDrawer(): void {
    this.$store.commit('toggleDrawer')
  }

  get showAwesomenessBadge(): boolean {
    return this.businessPlanCounter < defaultAwesomeness
  }

  selectYear(): void {
    this.$store.commit('updateSelectedYear', this.selectedYear)
  }

  selectMember(): void {
    this.$store.commit('updateSelectedMember', this.selectedMember)
  }

  countBusinessplan(): void {
    this.businessPlanCounter--
    (this.$refs.coinAudio as HTMLAudioElement).play().then(() => {
      if (this.businessPlanCounter <= 0) {
        this.businessPlanCounter = defaultAwesomeness
        this.showBusinessplan = true
      }
    })
  }
}

