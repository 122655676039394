
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class ErrorBanner extends Vue {

  @Prop() readonly errorData!: ErrorData | null

  get show(): boolean {
    return !!this.errorData
  }

  get code(): string {
    return this.errorData?.code || ''
  }

  get message(): string {
    return this.errorData?.message || ''
  }

}

export interface ErrorData {
  code?: string;
  message?: string;
}

