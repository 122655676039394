

import { fetchAsObjectUrl } from '@/application/RestApi'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class PhotoImg extends Vue {

  @Prop({ required: true, default: '' })
  src!: string

  objectUrl = ''

  // noinspection JSUnusedGlobalSymbols
  async mounted() {
    this.objectUrl = await fetchAsObjectUrl(this.src)
  }
}
