
import { TableModel } from '@/application/TablesSupport'
import BeteiligungenChip from '@/components/beteiligungen/BeteiligungenChip.vue'
import UebertragEdit from '@/components/uebertraege/UebertragEdit.vue'
import {
  Buchung,
  BuchungFieldsFragment,
  QueryBuchungenPagedArgs,
  TopfDetailsFragment,
  TopfQueryVariables,
  TopfStatus,
  TopfStimme,
  topfStimmeAbgebenMutation,
  TopfStimmeInput,
  TransaktionReferenceFieldsFragment,
  TransaktionStatus,
  UebertragFieldsFragment,
  UebertragQuery,
  UebertragQueryVariables,
  useBuchungenPagedQuery,
  useTopfQuery,
  useUebertragQuery
} from '@/generated/graphql'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    UebertragEdit,
    BeteiligungenChip
  },
  apollo: {
    topf: useTopfQuery<TopfDetailPage>({
      variables(): TopfQueryVariables {
        return {
          id: this.$route.params.id
        }
      }
    }),
    parentUebertragTemplate: useUebertragQuery<TopfDetailPage>({
      variables(): UebertragQueryVariables {
        if (this.parentUebertragTemplateId) {
          return {
            id: this.parentUebertragTemplateId
          }
        } else {
          throw new Error("undefined parentUebertragTemplateId")
        }
      },
      skip(): boolean {
        return !this.parentUebertragTemplateId
      },
      update(data: UebertragQuery): UebertragFieldsFragment {
        if (data.uebertrag) {
          return {
            id: '',
            version: '',
            status: TransaktionStatus.InBearbeitung,
            bezeichnung: 'Aufstockung Topf ' + this.topf?.bezeichnung,
            betrag: '0',
            datum: (new Date()).toISOString().substring(0, 10),
            von: data.uebertrag.von,
            nach: data.uebertrag.nach
          }
        } else {
          throw new Error("no uebertrag")
        }
      }
    }),
    buchungenPaged: useBuchungenPagedQuery<TopfDetailPage>({
      variables(): QueryBuchungenPagedArgs {
        if (this.topf) {
          return {
            kontoId: this.topf.kontoId,
            page: this.buchungen.pageable.page?.toString() ?? '0',
            size: this.buchungen.pageable.size?.toString() ?? '0',
            sort: this.buchungen.pageable.sort
          }
        } else {
          throw new Error("Topf is undefined")
        }
      },
      result({
        data,
        loading
      }): void {
        if (loading) {
          return
        }
        const parentUebertraege = data.buchungenPaged.content.filter((b: BuchungFieldsFragment) => b.sourceTransaktion?.__typename === 'Uebertrag')
        if (parentUebertraege.length > 0 && parentUebertraege[0].sourceTransaktion) {
          this.parentUebertragTemplateId = parentUebertraege[0].sourceTransaktion.id
        }
        this.buchungen.update(data.buchungenPaged.content, data.buchungenPaged.totalElements, data.buchungenPaged.totalPages)
      },
      skip(): boolean {
        return !this.topf
      }
    })
  }
})
export default class TopfDetailPage extends Vue {

  topf: TopfDetailsFragment | undefined
  showEditDialog = false
  parentUebertragTemplateId: string | null = null
  parentUebertragTemplate: UebertragFieldsFragment | null = null

  stimme: TopfStimmeInput = {
    memberId: this.$store.state.benutzer.memberId,
    veto: false
  }

  buchungen: TableModel<BuchungFieldsFragment> = new TableModel<BuchungFieldsFragment>([
        {
          text: 'Datum',
          value: 'valuta'
        },
        {
          text: 'Bezeichnung',
          value: 'bezeichnung'
        },
        {
          text: 'Betrag CHF',
          value: 'betrag',
          align: 'end'
        },
        {
          text: 'Sponsor/Empfänger',
          value: 'opposingKonten',
          sortable: false
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ],
      ['valuta', 'bezeichnung'], [true, false], 15)

  getUrl(reference: TransaktionReferenceFieldsFragment | undefined): string | undefined {
    switch (reference?.__typename) {
      case 'Ausgabe':
        return `/ausgaben/${reference?.id}`
      case 'Leistung':
        return `/leistungen/${reference?.id}`
      case 'Uebertrag':
        return `/uebertraege/${reference?.id}`
    }
  }

  open(item: Buchung): void {
    if (!item?.sourceTransaktion) {
      return
    }

    this.$router.push({ path: this.getUrl(item.sourceTransaktion as unknown as TransaktionReferenceFieldsFragment) })
  }

  createUebetrag(): void {
    this.showEditDialog = !this.showEditDialog
  }

  cancelEdit(): void {
    this.showEditDialog = !this.showEditDialog
  }

  onCompletedEdit(): void {
    this.showEditDialog = !this.showEditDialog
    this.$apollo.queries.buchungenPaged.refetch()
    this.$apollo.queries.topf.refetch()
  }

  isRequested = (t: TopfDetailsFragment): boolean => t.status === TopfStatus.Antrag

  async stimmeAbgeben(veto: boolean): Promise<void> {
    if (this.topf && this.topf.id) {
      this.stimme.veto = veto

      await topfStimmeAbgebenMutation(this, {
        variables: {
          id: this.topf.id,
          stimme: this.stimme
        }
      })
    }
  }

  get vetoNames(): string {
    const stimmen: TopfStimme[] = this.topf?.stimmen as TopfStimme[] ?? []
    return  this.printNames(stimmen, true)
  }

  get likeNames(): string {
    const stimmen: TopfStimme[] = this.topf?.stimmen as TopfStimme[] ?? []
    return this.printNames(stimmen, false)
  }

  private printNames = (stimmen: TopfStimme[], veto: boolean): string => stimmen.filter(s => s.veto === veto).map(s => s.member?.name).join(', ')

}
