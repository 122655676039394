
import { ApolloQueryResult } from 'apollo-client'

import { Component, Vue, Watch } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'
import {fetchAsObjectUrlAndOpenInNewWindow, loehneErstellen} from '@/application/RestApi'
import { notNegativeNumber } from '@/application/Validators'
import {
  Benutzer,
  BenutzerByMemberIdQuery,
  BenutzerByMemberIdQueryVariables,
  BenutzerFieldsFragment,
  BonusByMemberIdAndYearQuery,
  BonusByMemberIdAndYearQueryVariables, deleteLohnMutation,
  LoehneByMemberIdQuery,
  LoehneByMemberIdQueryVariables,
  Lohnausweis,
  LohnausweisByMemberIdQuery,
  LohnausweisByMemberIdQueryVariables,
  LohnFieldsFragment, MeDocument,
  Member,
  MemberDashboardQueryVariables,
  MeQuery,
  MeQueryVariables,
  UpdateBenutzerInput, updateBenutzerMutation, useBenutzerByMemberIdQuery, useBonusByMemberIdAndYearQuery, useLoehneByMemberIdQuery, useLohnausweisByMemberIdQuery, useMemberDashboardQuery
} from '@/generated/graphql'

@Component({
  apollo: {
    bonus: useBonusByMemberIdAndYearQuery<LohnPage>({
      variables(): BonusByMemberIdAndYearQueryVariables {
        if (this.selectedMember) {
          return {
            id: this.selectedMember,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error("no member selected")
        }
      },
      skip(): boolean {
        return this.selectedMonat !== this.bonusMonat
      },
      update(data: BonusByMemberIdAndYearQuery): string {
        return data.bonusByMemberIdAndYear
      }
    }),
    member: useMemberDashboardQuery<LohnPage>({
      variables(): MemberDashboardQueryVariables {
        if (this.selectedMember) {
          return {
            id: this.selectedMember,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error("no member selected")
        }
      },
      skip(): boolean {
        return !this.selectedMember
      }
    }),
    benutzer: useBenutzerByMemberIdQuery<LohnPage>({
      variables(): BenutzerByMemberIdQueryVariables {
        if (this.member) {
          return {
            id: this.member.id,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error("no member")
        }
      },
      skip(): boolean {
        return !this.member
      },
      update(data: BenutzerByMemberIdQuery): BenutzerFieldsFragment {
        if (data.member.benutzer.bvgLastYear) {
          this.bvgLastYear = Number(data.member.benutzer.bvgLastYear.arbeitnehmerAnteil)
        }
        return data.member.benutzer
      }
    }),
    loehne: useLoehneByMemberIdQuery<LohnPage>({
      variables(): LoehneByMemberIdQueryVariables {
        if (this.member) {
          return {
            id: this.member.id,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error("no member")
        }
      },
      skip(): boolean {
        return !this.member
      },
      update(data: LoehneByMemberIdQuery): Array<LohnFieldsFragment> {
        this.total = JSON.parse(JSON.stringify(this.totalInit))
        this.total = data.member.loehne.reduce((a: LohnFieldsFragment, b: LohnFieldsFragment) => {
          a.ahvIvEoAn = a.ahvIvEoAn + parseFloat(b.ahvIvEoAn)
          a.ahvIvEoAg = a.ahvIvEoAg + parseFloat(b.ahvIvEoAg)
          a.alvAn = a.alvAn + parseFloat(b.alvAn)
          a.alvAg = a.alvAg + parseFloat(b.alvAg)
          a.alv2An = a.alv2An + parseFloat(b.alv2An)
          a.alv2Ag = a.alv2Ag + parseFloat(b.alv2Ag)
          a.nbuAn = a.nbuAn + parseFloat(b.nbuAn)
          a.buAg = a.buAg + parseFloat(b.buAg)
          a.bvgAn = a.bvgAn + parseFloat(b.bvgAn)
          a.bvgAg = a.bvgAg + parseFloat(b.bvgAg)
          a.ktgAg = a.ktgAg + parseFloat(b.ktgAg)
          a.fakAg = a.fakAg + parseFloat(b.fakAg)
          a.ahvVerwaltungAg = a.ahvVerwaltungAg + parseFloat(b.ahvVerwaltungAg)
          a.totalAbzuegeAn = a.totalAbzuegeAn + parseFloat(b.totalAbzuegeAn)
          a.totalAbzuegeAg = a.totalAbzuegeAg + parseFloat(b.totalAbzuegeAg)
          a.kinderzulagen = a.kinderzulagen + parseFloat(b.kinderzulagen)
          a.brutto = a.brutto + parseFloat(b.brutto)
          a.netto = a.netto + parseFloat(b.netto)
          a.auszahlbar = a.auszahlbar + parseFloat(b.auszahlbar)
          return a
        }, JSON.parse(JSON.stringify(this.totalInit)))
        return data.member.loehne
      }
    }),
    lohnausweis: useLohnausweisByMemberIdQuery<LohnPage>({
      variables(): LohnausweisByMemberIdQueryVariables {
        if (this.member) {
          return {
            id: this.member.id,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error("no member")
        }
      },
      skip(): boolean {
        return !this.member || this.bvgLastYear < 0
      },
      update(data: LohnausweisByMemberIdQuery): Lohnausweis {
        return data.member.lohnausweis
      }
    })
  }
})
export default class LohnPage extends Vue {

  totalInit = {
    ahvIvEoAn: 0,
    ahvIvEoAg: 0,
    alvAn: 0,
    alvAg: 0,
    alv2An: 0,
    alv2Ag: 0,
    nbuAn: 0,
    buAg: 0,
    bvgAn: 0,
    bvgAg: 0,
    ktgAg: 0,
    fakAg: 0,
    ahvVerwaltungAg: 0,
    totalAbzuegeAn: 0,
    totalAbzuegeAg: 0,
    kinderzulagen: 0,
    brutto: 0,
    netto: 0,
    auszahlbar: 0
  }

  total = {}

  lohnausweis = null
  bvgLastYear = -1

  benutzer: Benutzer | null = null
  readonly memberId = this.$store.state.benutzer.memberId
  readonly isBuchhalter = this.$store.state.benutzer.isBuchhalter

  // noinspection JSUnusedGlobalSymbols
  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  get isMyself(): boolean {
    return this.$store.state.benutzer.memberId === this.member?.id
  }

  get selectedMember(): string | undefined {
    if (this.isBuchhalter) {
      if (this.$store.state.benutzer.selectedMember && this.$store.state.benutzer.selectedMember !== '-1') {
        return this.$store.state.benutzer.selectedMember
      } else {
        return this.$store.state.benutzer.memberId
      }
    } else {
      return this.$store.state.benutzer.id
    }
  }

  readonly notNegativeNumber: InputValidationRules = [notNegativeNumber]
  loehne: Array<LohnFieldsFragment> = []
  bonus = 0
  arbeitgeberAnteileAnzeigen = false
  member: Member | null = null
  bonusMonat = 'Bonus (13-Monat)'
  monate = ['Alle', 'Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember', this.bonusMonat]
  selectedMonat = this.monate[new Date().getMonth() + 1]
  formValid = false
  loehneErstellenMemberRunning = false
  loehneErstellenRunning = false
  lohnLoeschenRunning = new Map<string, boolean>()
  saveBenutzerRunning = false

  get isBonusSet(): boolean {
    return this.selectedMonat === this.bonusMonat && this.bonus >= 0
  }

  @Watch('selectedMonat')
  handleDialogClosing(): void {
    if (this.selectedMonat === this.bonusMonat) {
      this.$apollo.queries.bonus.refetch()
    } else {
      this.bonus = 0
    }
  }

  async loehneErstellen(selectedMember: string): Promise<void> {
    selectedMember ? this.loehneErstellenMemberRunning = true : this.loehneErstellenRunning = true
    const idx = this.monate.findIndex(x => x === this.selectedMonat)
    const yearMonth = idx !== 0 ? `${this.selectedYear}-${idx < 10 ? '0' : ''}${idx}` : undefined
    const lohnFromBonusFieldOrUndefined = this.isBonusSet ? this.bonus : undefined // null = take the defined Lohn from meber profile
    await loehneErstellen(yearMonth, selectedMember, lohnFromBonusFieldOrUndefined, true)
    selectedMember ? this.loehneErstellenMemberRunning = false : this.loehneErstellenRunning = false
    this.$apollo.queries.loehne.refetch()
    this.$apollo.queries.lohnausweis.refetch()
  }

  async lohnLoeschen(id: string): Promise<void> {
    this.lohnLoeschenRunning.set(id, true)
    await deleteLohnMutation(this, {
      variables: { id }
    })
    this.lohnLoeschenRunning.set(id, false)
    this.$apollo.queries.loehne.refetch()
    this.$apollo.queries.lohnausweis.refetch()
  }

  async saveBenutzer(): Promise<void> {
    this.saveBenutzerRunning = true

    try {
      if (this.benutzer) {
        const input: UpdateBenutzerInput = {
          id: this.benutzer.id,
          name: this.benutzer.name,
          email: this.benutzer.email,
          adresse: this.benutzer.adresse,
          isMember: this.benutzer.isMember,
          isBuchhalter: this.benutzer.isBuchhalter,
          aufMandatSuche: this.benutzer.aufMandatSuche,
          geburtstag: this.benutzer.geburtstag,
          geschlecht: this.benutzer.geschlecht,
          kinderzulagen: this.benutzer.kinderzulagen,
          ahv: this.benutzer.ahv,
          mobile: this.benutzer.mobile,
          telegram: this.benutzer.telegram,
          linkedin: this.benutzer.linkedin,
          twitter: this.benutzer.twitter,
          webpage: this.benutzer.webpage,
          beschaeftigungsgrad: this.benutzer.beschaeftigungsgrad,
          monatslohn: this.benutzer.monatslohn,
          ktg: this.benutzer.ktg,
          bvg: this.benutzer.bvg,
          bvgLastYear: {
            arbeitnehmerAnteil: this.bvgLastYear.toString(),
            arbeitgeberAnteil: this.bvgLastYear.toString()
          },
          bankverbindung: this.benutzer.bankverbindung,
          isLohnAutomatischGenerieren: this.benutzer.isLohnAutomatischGenerieren,
          hasGA: this.benutzer.hasGA,
          hasVerpflegung: this.benutzer.hasVerpflegung,
          pauschalspesen: this.benutzer.pauschalspesen,
          arbeitspensum: this.benutzer.arbeitspensum,
          unbezahlteMonate: this.benutzer.unbezahlteMonate
        }

        await updateBenutzerMutation(this, {
          variables: {
            input
          }
        })

        const meQuery: ApolloQueryResult<MeQuery> = await this.$apollo.query<MeQuery, MeQueryVariables>({
          query: MeDocument,
          variables: {
            year: this.selectedYear.toString()
          }
        })
        this.$store.commit('update', meQuery.data.me)
        await this.$apollo.queries.benutzer.refetch()
        await this.$apollo.queries.bonus.refetch()
      }
    } finally {
      this.saveBenutzerRunning = false
    }
  }

  async downloadMonatsauszug(yearMonth: string) {
    await fetchAsObjectUrlAndOpenInNewWindow(`Monatsauszug ${yearMonth}.pdf`, `/api/lohn/monatsauszug/${yearMonth}/${this.selectedMember}`)
  }

  async downloadLohnausweis() {
    await fetchAsObjectUrlAndOpenInNewWindow(`Lohnausweis ${this.selectedYear}`, `/api/lohn/lohnausweis/${this.selectedYear}/${this.selectedMember}`)
  }

}
