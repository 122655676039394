
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class EditDialogAction extends Vue {

  @Prop()
  readonly color!: string | undefined

  @Prop({ default: null })
  readonly tooltip!: string | null

  @Prop({ default: false })
  readonly disabled!: boolean

  @Prop({ default: false })
  readonly loading!: boolean

  get isMobilePrimaryButton(): boolean {
    return this.$vuetify.breakpoint.mobile && this.color === 'primary'
  }

  get effectiveColor(): string | undefined {
    return this.isMobilePrimaryButton ? undefined : this.color
  }

}
