
import { bruttoausnetto } from '@/application/RestApi'
import { notNegativeNumber, positiveNumber, rulesRequired, rulesRequiredNotNegativeNumber } from '@/application/Validators'
import {
  BankverbindungInput,
  BenutzerByMemberIdQuery,
  BenutzerByMemberIdQueryVariables,
  BenutzerFieldsFragment,
  Geschlecht,
  KtgInput,
  Lohnausnahmen,
  UpdateBenutzerInput,
  updateBenutzerMutation,
  useBenutzerByMemberIdQuery
} from '@/generated/graphql'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { InputValidationRules } from 'vuetify'

@Component({
  apollo: {
    benutzer: useBenutzerByMemberIdQuery<ProfilePage>({
      variables(): BenutzerByMemberIdQueryVariables {
        if (this.selectedMember) {
          return {
            id: this.selectedMember,
            year: this.selectedYear.toString()
          }
        } else {
          throw new Error("no selected member")
        }
      },
      update(data: BenutzerByMemberIdQuery): BenutzerFieldsFragment {
        if (data.member.benutzer.bvg) {
          this.bvg = data.member.benutzer.bvg.arbeitnehmerAnteil
        }
        if (data.member.benutzer.bvgLastYear) {
          this.bvgLastYear = data.member.benutzer.bvgLastYear.arbeitnehmerAnteil
        }
        if (data.member.benutzer.ktg) {
          this.ktg = data.member.benutzer.ktg
        } else {
          this.ktg = { tage: this.ktgtage[0].toString() }
        }
        if (data.member.benutzer.bankverbindung) {
          this.bankverbindung = data.member.benutzer.bankverbindung
        }
        this.benutzer = data.member.benutzer
        return data.member.benutzer
      },
      skip(): boolean {
        return !this.selectedMember
      }
    })
  }
})
export default class ProfilePage extends Vue {

  monatslohnAusnahmenSelectedMonth: string|null = null
  monatslohnAusnahmenLohn: number|null = null

  readonly positiveNumber: InputValidationRules = [positiveNumber]

  benutzer: BenutzerFieldsFragment | null = null
  nettolohn: number | null = null

  readonly notNegativeNumber: InputValidationRules = [notNegativeNumber]
  readonly rulesRequiredNotNegativeNumber: InputValidationRules = rulesRequiredNotNegativeNumber
  readonly rulesRequired = rulesRequired

  ktg: KtgInput = { tage: '0' }
  bvg = ''
  bvgLastYear = ''

  bankverbindung: BankverbindungInput = {
    iban: '',
    bic: '',
    name: ''
  }

  readonly isBuchhalter = this.$store.state.benutzer.isBuchhalter

  get selectedMember(): string | null {
    if (this.isBuchhalter) {
      if (this.$store.state.benutzer.selectedMember && this.$store.state.benutzer.selectedMember !== '-1') {
        return this.$store.state.benutzer.selectedMember
      } else {
        return null
      }
    } else {
      return this.$store.state.benutzer.id
    }
  }

  get selectedYear(): number {
    return this.$store.state.benutzer.selectedYear
  }

  get isMyself(): boolean {
    return this.$store.state.benutzer.id === this.benutzer?.id
  }

  menu = false
  formValid = false
  saving = false

  @Watch('menu')
  menuChanged(val: boolean): void {
    val && setTimeout(() => ((this.$refs.picker as Vue & { activePicker: string }).activePicker = 'YEAR'))
  }

  saveDate(date: string): void {
    (this.$refs.menu as Vue & { save: (date: string) => void }).save(date)
  }

  async save(): Promise<void> {
    this.saving = true
    try {
      if (this.benutzer) {
        const input: UpdateBenutzerInput = {
          id: this.benutzer.id,
          name: this.benutzer.name,
          email: this.benutzer.email,
          adresse: this.benutzer.adresse,
          isMember: this.benutzer.isMember,
          isBuchhalter: this.benutzer.isBuchhalter,
          aufMandatSuche: this.benutzer.aufMandatSuche,
          geburtstag: this.benutzer.geburtstag,
          geschlecht: this.benutzer.geschlecht,
          kinderzulagen: this.benutzer.kinderzulagen,
          ahv: this.benutzer.ahv,
          mobile: this.benutzer.mobile,
          telegram: this.benutzer.telegram,
          linkedin: this.benutzer.linkedin,
          twitter: this.benutzer.twitter,
          webpage: this.benutzer.webpage,
          beschaeftigungsgrad: this.benutzer.beschaeftigungsgrad,
          monatslohn: this.benutzer.monatslohn,
          ktg: this.ktg,
          bvg: {
            arbeitnehmerAnteil: this.bvg,
            arbeitgeberAnteil: this.bvg
          },
          bvgLastYear: {
            arbeitnehmerAnteil: this.bvgLastYear,
            arbeitgeberAnteil: this.bvgLastYear
          },
          bankverbindung: this.bankverbindung,
          isLohnAutomatischGenerieren: this.benutzer.isLohnAutomatischGenerieren,
          hasGA: this.benutzer.hasGA,
          hasVerpflegung: this.benutzer.hasVerpflegung,
          pauschalspesen: this.benutzer.pauschalspesen,
          arbeitspensum: this.benutzer.arbeitspensum,
          unbezahlteMonate: this.benutzer.unbezahlteMonate,
          monatslohnAusnahmen: this.benutzer.monatslohnAusnahmen
        }

        await updateBenutzerMutation(this, {
          variables: {
            input
          }
        })

        await this.$apollo.queries.benutzer.refetch()

        if (this.benutzer.id === this.$store.state.benutzer.id) {
          this.$store.commit('update', this.benutzer)
        }
      }
    } finally {
      this.saving = false
    }
  }

  geschlechter = [Geschlecht.Maennlich, Geschlecht.Weiblich, Geschlecht.Andere]
  ktgtage = ['30', '60']

  get yearMonthsOptions(): Array<string> {
    return [...Array(12).keys()].map((item, index) => `${this.selectedYear}-${index < 9 ? '0' : ''}${index + 1}`)
  }

  removeYearMonth(item: string) {
    if (this.benutzer) {
      this.benutzer.unbezahlteMonate.splice(this.benutzer.unbezahlteMonate.indexOf(item), 1)
      this.benutzer.unbezahlteMonate = [...this.benutzer.unbezahlteMonate]
    }
  }

  addMonatslohnAusnahme(yearMonth: string, monatslohn: number): void {
    if (this.benutzer) {
      if (!this.benutzer.monatslohnAusnahmen) {
        this.benutzer.monatslohnAusnahmen = []
      }
      const entry = {
        yearMonth,
        monatslohn: `${monatslohn}`
      }
      this.benutzer.monatslohnAusnahmen = this.benutzer.monatslohnAusnahmen.filter(x => x?.yearMonth !== yearMonth)
      this.benutzer.monatslohnAusnahmen.push(entry)
      this.benutzer.monatslohnAusnahmen.sort((a, b) => {
        if (a && b) {
          const x = a.yearMonth
          const y = b.yearMonth
          if (x > y) {
            return 1
          }
          if (x < y) {
            return -1
          }
          return 0
        } else {
          return 0
        }
      })
    }
  }

  deleteMonatslohnAusnahme(monatslohnAusnahme: Lohnausnahmen): void {
    if (this.benutzer) {
      this.benutzer.monatslohnAusnahmen = this.benutzer.monatslohnAusnahmen?.filter(x => x?.yearMonth !== monatslohnAusnahme.yearMonth)
    }
  }

  async bruttoausnetto(): Promise<void> {
    let ktgTyp = ''
    switch (this.benutzer?.geschlecht) {
      case Geschlecht.Weiblich: {
        ktgTyp = `FRAU${this.benutzer?.ktg?.tage}`
        break
      }
      case Geschlecht.Maennlich: {
        ktgTyp = `MANN${this.benutzer?.ktg?.tage}`
        break
      }
      default: {
        break
      }
    }
    const brutto = await bruttoausnetto(this.nettolohn ? this.nettolohn : 0,
        ktgTyp,
        this.benutzer?.bvg?.arbeitnehmerAnteil ? parseFloat(this.benutzer.bvg.arbeitnehmerAnteil) : 0,
        this.selectedMember ? this.selectedMember : '-1',
        this.selectedYear ? this.selectedYear : -1)
    if (this.benutzer) {
      this.benutzer.monatslohn = brutto
    }
    
  }

}
