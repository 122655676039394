
import PhotoImg from '@/components/PhotoImg.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Member } from '@/generated/graphql'
@Component({
  components: { PhotoImg }
})
export default class ErbringerChip extends Vue {

  @Prop({
    required: true
  })
  erbringer!: Member

}
