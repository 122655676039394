

import { Component, Vue, Watch } from 'vue-property-decorator'
import { DataTableHeader } from 'vuetify'
import { TableModel } from '@/application/TablesSupport'
import KundeEdit from '@/components/kunden/KundeEdit.vue'
import { useAllKundenQuery } from '@/generated/graphql'

const pageTitle = 'Kunden'

@Component({
  components: {
    KundeEdit
  },
  metaInfo: { title: pageTitle },
  apollo: {
    kunden: useAllKundenQuery<KundenPage>({
      result({ data, loading }): void {
        if (loading) {
          return
        }
        const items: TableModelType[] = data.kunden.map((k) => ({
            id: k.id,
            bezeichnung: k.bezeichnung,
            adresse: (k.strasse ? k.strasse : '') + '' + (k.plz ? ', ' + k.plz : '') + ' ' + (k.stadt ? k.stadt : ''),
            email: k.email
        }))
        this.tableModel.update(items, 1000, '1')
      }
    })
  }
})
export default class KundenPage extends Vue {

  readonly tableModel = new TableModel<TableModelType>([
    {
      text: 'Bezeichung',
      value: 'bezeichnung',
      sortable: false
    },
    {
      text: 'Adresse',
      value: 'adresse',
      sortable: false
    },
    {
      text: 'E-Mail',
      value: 'email'
    },
    {
      text: '',
      value: 'actions',
      sortable: false
    }
  ], [], [true, false], 1000)

  showEditDialog = false
  selectedId: string | null = null

  @Watch('$route', { immediate: true })
  routeChange(): void {
    const id = this.$route.params.id

    if (id) {
      this.selectedId = id
      this.showEditDialog = true
    } else if ('create' in this.$route.query) {
      this.selectedId = null
      this.showEditDialog = true
    } else {
      this.selectedId = null
      this.showEditDialog = false
    }
  }

  get tableHeaders(): DataTableHeader[] {
    return this.$vuetify.breakpoint.lgAndUp ? this.tableModel.headers : this.tableModel.headers.filter(value => !value.hideIfMobile)
  }

  createItem(): void {
    this.$router.push({
      name: 'kunden-id',
      params: {},
      query: { create: 'true' }
    })
  }

  editItem(id: string): void {
    this.$router.push({
      name: 'kunden-id',
      params: { id }
    })
  }

  @Watch('showEditDialog')
  handleDialogClosing(): void {
    if (!this.showEditDialog) {
      this.$router.push({ name: 'kunden-id' })
    }
  }

  cancelEdit(): void {
    this.$router.push({ name: 'kunden-id' })
  }

  onCompletedEdit(): void {
    this.$apollo.queries.kunden.refetch()
    this.$router.push({ name: 'kunden-id' })
  }
}

type TableModelType = {
  id: string,
  bezeichnung: string,
  adresse: string,
  email?: string | null
}
