import store from '@/store'

function createDefaultHeaders(acceptJson = true) {
  const headers: Record<string, string> = {}

  headers['X-Requested-With'] = 'XMLHttpRequest'

  if (acceptJson) {
    headers['accept'] = 'application/json'
  }

  const jwt = store.state.benutzer.jwt
  if (jwt) {
    headers['Authorization'] = `Bearer ${jwt}`
  }
  return headers
}

/**
 * Returns the BelegId of the created Beleg.
 *
 * @param file
 */
export async function uploadBeleg(file: File): Promise<string> {
  const requestBody = new FormData()
  requestBody.append('file', file as Blob, file.name)
  const response = await fetch('/api/belege', {
    method: 'POST',
    body: requestBody,
    headers: createDefaultHeaders()
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.text()
}

export async function deleteBeleg(id: string): Promise<string> {

  const response = await fetch(`/api/belege/${id}`, {
    method: 'DELETE',
    headers: createDefaultHeaders()
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }

  return response.text()
}

export async function bruttoausnetto(netto: number, ktgTyp: string, bvgAn: number, memberId: string, year: number): Promise<string> {
  const requestBody = new FormData()
  requestBody.append('netto', netto.toString())
  requestBody.append('bvgAn', bvgAn.toString())
  requestBody.append('ktgTyp', ktgTyp.toString())
  requestBody.append('memberId', memberId)
  requestBody.append('year', year.toString())
  const response = await fetch('/api/lohn/bruttoausnetto', {
    method: 'POST',
    body: requestBody,
    headers: createDefaultHeaders()
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.text()
}

export async function loehneErstellen(yearMonth?: string, benutzerId?: string, brutto?: number, force?: boolean): Promise<string> {
  const requestBody = new FormData()
  yearMonth && requestBody.append('yearMonth', yearMonth)
  brutto && requestBody.append('brutto', brutto.toString())
  benutzerId && requestBody.append('benutzerId', benutzerId)
  force && requestBody.append('force', `${force}`)

  const response = await fetch('/api/lohn/generate', {
    method: 'POST',
    body: requestBody,
    headers: createDefaultHeaders()
  })

  if (!response.ok) {
    throw new Error(response.statusText)
  }
  return response.text()
}

async function fetchAsBlob(url: string): Promise<Blob> {
  const response = await fetch(url, {
    headers: createDefaultHeaders(false)
  })

  if (response.ok) {
    return await response.blob()
  } else {
    throw new Error(`Could not load blob from url: ${url} ${response.statusText}`)
  }
}

export async function fetchAsObjectUrl(url: string): Promise<string> {
  const blob = await fetchAsBlob(url)
  return URL.createObjectURL(blob)
}

export async function fetchAsObjectUrlAndOpenInNewWindow(filename: string, url: string) {
  const link = document.createElement('a')
  link.href = await fetchAsObjectUrl(url)
  link.download = filename
  link.click()
  URL.revokeObjectURL(link.href)
}