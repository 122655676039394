
import { Component, Prop, Vue } from 'vue-property-decorator'
import EditDialogAction from '@/components/forms/EditDialogAction.vue'

@Component({
    components: { EditDialogAction }
  })
  export default class EditDialogCard extends Vue {

    @Prop({ required: true })
    readonly title!: string

    @Prop({ required: false })
    readonly share: string | undefined

    @Prop({ required: false, default: false })
    readonly fullHeight!: boolean

    get textClass(): string | undefined {
      if (this.$vuetify.breakpoint.mobile) {
        return 'card-body-mobile'
      }
      if (this.fullHeight) {
        return 'card-body-desktop'
      }
      return undefined
    }

    cancel(): void {
      this.$emit('cancel')
    }
  }
