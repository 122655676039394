import { render, staticRenderFns } from "./ErrorBanner.vue?vue&type=template&id=3b245b53&scoped=true&"
import script from "./ErrorBanner.vue?vue&type=script&lang=ts&"
export * from "./ErrorBanner.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b245b53",
  null
  
)

export default component.exports