import { DataOptions, DataTableHeader } from 'vuetify'
import { Pageable } from './BackendTypes'

export class TableModel<T> {
  totalItems = 0

  totalPages = 0

  items = new Array<T>()

  readonly headers: MobileAwareDataTableHeader[]

  options: DataOptions

  loading = false

  update(items: T[] | undefined, totalItems: number | string | undefined, totalPages: string | undefined): void {
    this.items = items || []
    if (typeof totalItems === 'number') {
      this.totalItems = totalItems
    } else if (totalItems) {
      this.totalItems = Number.parseInt(totalItems)
    } else {
      this.totalItems = 0
    }

    this.totalPages = Number.parseInt(totalPages || '0')
  }

  get pageable(): Pageable {
    const sort: string[] = []
    for (let i = 0; i < this.options.sortBy.length; i++) {
      sort.push(`${this.options.sortBy[i]},${this.options.sortDesc[i] ? 'desc' : 'asc'}`)
    }

    return {
      page: this.options.page - 1,
      size: this.options.itemsPerPage,
      sort
    }
  }

  constructor(headers: MobileAwareDataTableHeader[], sortBy: string[], sortDesc: boolean[], defaultItemsPerPage?: number) {
    this.headers = headers
    this.options = {
      itemsPerPage: defaultItemsPerPage || 20,
      page: 1,
      sortBy,
      sortDesc,
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false
    }
  }
}

export interface MobileAwareDataTableHeader extends DataTableHeader {
  hideIfMobile?: boolean;
}
