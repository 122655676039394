import BonusPage from '@/pages/admin/BonusPage.vue'
import LoehnePage from '@/pages/admin/LoehnePage.vue'
import MembersPage from '@/pages/admin/MembersPage.vue'
import SpesenPage from '@/pages/admin/SpesenPage.vue'
import AusgabenPage from '@/pages/ausgaben/AusgabenPage.vue'
import DashboardPage from '@/pages/dashboard/DashboardPage.vue'
import ErrorPage from '@/pages/ErrorPage.vue'
import KundenPage from '@/pages/kunden/KundenPage.vue'
import LeistungenPage from '@/pages/leistungen/LeistungenPage.vue'
import LohnPage from '@/pages/lohn/LohnPage.vue'
import ProfilePage from '@/pages/profile/ProfilePage.vue'
import TopfDetailPage from '@/pages/toepfe/TopfDetailPage.vue'
import TopfPage from '@/pages/toepfe/TopfPage.vue'
import UebertraegePage from '@/pages/uebertraege/UebertraegePage.vue'
import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { path: "/", component: DashboardPage, name: "dashboard" },
  { path: "/toepfe", component: TopfPage, name: "toepfe" },
  { path: "/ausgaben/:id?", component: AusgabenPage, name: "ausgaben-id" },
  { path: "/kunden/:id?", component: KundenPage, name: "kunden-id" },
  { path: "/leistungen/:id?", component: LeistungenPage, name: "leistungen-id" },
  { path: "/profile/:id?", component: ProfilePage, name: "profile-id" },
  { path: "/toepfe/:id", component: TopfDetailPage, name: "toepfe-id" },
  { path: "/lohn", component: LohnPage, name: "lohn" },
  { path: "/uebertraege/:id?", component: UebertraegePage, name: "uebertraege-id" },
  { path: '/admin/bonus', component: BonusPage, name: 'admin-bonus' },
  { path: '/admin/loehne', component: LoehnePage, name: 'admin-loehne' },
  { path: '/admin/members', component: MembersPage, name: 'admin-members' },
  { path: '/admin/spesen', component: SpesenPage, name: 'admin-spesen' },
  { path: "/error", component: ErrorPage, name: "error" },
  { path: '*', redirect: { name: 'error', query: { error: '404' } }},
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
