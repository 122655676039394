
import PhotoImg from '@/components/PhotoImg.vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Beteiligung, KontoMinimalFieldsFragment } from '@/generated/graphql'

function isBeteiligungArray(arr: Beteiligung[] | KontoMinimalFieldsFragment[]): arr is Beteiligung[] {
  if (arr.length > 0) {

    return 'betrag' in arr[0]
  }

  return true
}
@Component({
  components: { PhotoImg }
})
export default class BeteiligungenChip extends Vue {

  @Prop({
    required: true,
    default: []
  })
  beteiligungen!: Beteiligung[] | KontoMinimalFieldsFragment[]

  get imageSrc(): string | null | undefined {
    if (this.konten.length === 1) {
      return this.konten[0].iconUrl
    }

    return undefined
  }

  get multiple(): boolean {
    return this.beteiligungen.length > 1
  }

  get text(): string {
    if (this.konten.length === 1) {
      return this.konten[0].bezeichnung
    }
    if (this.konten.length > 1) {
      return `${this.konten.length} Member/Töpfe`
    }

    return '-'
  }

  private get konten(): KontoMinimalFieldsFragment[] {
    if (this.beteiligungen.length > 0) {
      if (isBeteiligungArray(this.beteiligungen)) {
        return this.beteiligungen.map(value => value.konto)
      } else {
        return this.beteiligungen
      }
    }

    return []
  }
}
